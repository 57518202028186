import axios from "axios";
import ApiUrl from '../../config/ApiUrl';

class FixacaoPrecoContratoInternoService {
    salvar(dados) {
        const config = {
            headers: {
                'content-type': 'application/json'
            }
        }

        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-contrato-interno`, dados, config);
    }

    buscarPorId(idFixacaoPrecoContratoInterno) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-contrato-interno/${ idFixacaoPrecoContratoInterno }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-contrato-interno/buscar-com-paginacao`, { params });
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-contrato-interno/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-contrato-interno/ativar/${ id }`);
    }
}

export default new FixacaoPrecoContratoInternoService();