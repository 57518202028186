<template>
    <Fieldset legend="Regra de Fixação">
        <div class="grid p-fluid formgrid">
            <div class="col-4 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field flex align-items-end">
                    <label class="mr-2">
                        <strong>Regra:</strong>
                    </label>
                    <InputText v-model="dados.regra" class="w-full" :disabled="true" />
                </div>
            </div>
        </div>

        <div class="grid p-fluid formgrid">
            <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-10">
                <DataTable :value="fixacoesDePreco" rowGroupMode="rowspan" groupRowsBy="indicador.sigla" showGridlines>
                    <Column field="indicador.sigla" header="Indicador" style="width:16rem;">
                        <template #body="{ data }">
                            <div class="flex align-items-center">
                                <i v-if="indicadores[data.indicador.sigla] == null" class="pi pi-exclamation-triangle mr-1" style="font-size: 1.3rem; color: #cb8c07;"></i>
                                <i v-if="indicadores[data.indicador.sigla] != null" class="pi pi-check-circle mr-1" style="font-size: 1.3rem; color: #16a34a;"></i>
                                {{ data.indicador.sigla }}
                            </div>
                        </template>
                    </Column>
                    
                    <Column field="precoUnitario" header="Valor Unit." class="alinhar-direita-titulo-tabela" style="width:10rem;">
                        <template #body="{ data, field }">
                            R$ {{ formatDecimal(data[field], 4, 4) }}
                        </template>
                    </Column>

                    <Column field="quantidade" header="Quantidade" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                        <template #body="{ data, field }">
                            {{ formatDecimal(data[field], 2, 2) }} {{ siglaUnidadeMedida }}
                        </template>
                    </Column>
                    
                    <Column field="qtdFixacaoPendente" header="Fix. Pendente" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                        <template #body="{ data, field }">
                            {{ formatDecimal(data[field], 2, 2) }} {{ siglaUnidadeMedida }}
                        </template>
                    </Column>
                    
                    <Column field="qtdFixada" header="Qt. Fixada" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                        <template #body="{ data, field }">
                            {{ formatDecimal(data[field], 2, 2) }} {{ siglaUnidadeMedida }}
                        </template>
                    </Column>
                    
                    <Column field="dataFixacao" header="Data" style="width:8rem;">
                        <template #body="{ data, field }">
                            {{ convertDateToString(data[field], 'DD/MM/YYYY') }}
                        </template>
                    </Column>
                    
                    <Column field="dataBase" header="Mês/Ano Base" style="width:8rem;">
                        <template #body="{ data, field }">
                            {{ formatDateCustom(data[field], "MMMM/YYYY")  }}
                        </template>
                    </Column>

                    <Column field="fixar" header="Fixar" style="width:10rem;">
                        <template #body="{ data }">
                            <div class="flex align-items-center">                                
                                <RadioButton 
                                    v-model="indicadores[data.indicador.sigla]"
                                    id="sim"
                                    :value="data.id"
                                    :disabled="!data.ativo || data.id == null || data.id == undefined || quantidade == null || quantidade == 0 || quantidade > data.qtdPendente"
                                    :class="{ 'p-invalid': campoInvalido.fixar && quantidade <= data.qtdPendente }"
                                />
                                <label for="sim" class="ml-2">Sim</label>
                            </div>
                        </template>
                    </Column>
                    
                    <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela"  >
                        <template #body="{ data }">
                            <div class="flex align-items-center justify-content-center">
                                <Button 
                                    title="Visualizar histórico"
                                    icon="pi pi-list"
                                    class="p-button-rounded p-button-warning tamanho-botao-tabela flex-none mr-2"
                                    :disabled="data.id == null || data.id == undefined"
                                    @click="visualizarHistorico(data)"
                                ></Button>


                                <Button 
                                    v-if="data.id == null || data.id == undefined"
                                    icon="pi pi-plus"
                                    aria-label="Incluir" 
                                    class="p-button-info p-button-rounded tamanho-botao-tabela mr-2"
                                    :disabled="data.itemComposicaoAltera == false"
                                    @click="fixarCotacaoIndicadorEditar(data)"
                                ></Button>

                                <Button
                                    v-if="data.id != null && data.id != undefined"
                                    class="p-button-rounded p-button-info tamanho-botao-tabela flex-none mr-2"
                                    icon="pi pi-pencil"
                                    aria-label="Editar"
                                    :disabled="data.itemComposicaoAltera == false || !data.ativo"
                                    @click="fixarCotacaoIndicadorEditar(data)"
                                ></Button>

                                <Button 
                                    v-if="!data.ativo"
                                    title="Ativar"
                                    class="p-button-rounded p-button-info tamanho-botao-tabela flex-none"
                                    icon="pi pi-check-circle"
                                    :disabled="data.itemComposicaoAltera == false"
                                    @click="fixarCotacaoIndicadorAtivar(data.id)"
                                ></Button>
                                
                                <Button 
                                    v-if="data.ativo"
                                    class="p-button-rounded p-button-danger tamanho-botao-tabela flex-none"
                                    icon="pi pi-ban"
                                    aria-label="Inativar"
                                    :disabled="data.itemComposicaoAltera == false || data.id == null || data.id == undefined"
                                    @click="fixarCotacaoIndicadorInativar(data.id)"
                                ></Button>
                            </div>
                        </template>

                        <template #footer>
                            <Button 
                                icon="pi pi-plus"
                                aria-label="Incluir" 
                                class="p-button-info p-button-rounded tamanho-botao-tabela"
                                @click="fixarCotacaoIndicadorNovo"
                            ></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-2">
                <div class="grid p-fluid formgrid">
                    <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                        <div class="field">
                            <strong>Informação da Fixação</strong>
                        </div>
                    </div>

                    <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-12" v-if="dados.tipoFixacaoCodigo!='FIXO_DOLAR'">
                        <div class="field">
                            <div class="flex align-items-center">
                                <RadioButton v-model="dados.tipoFixacaoCodigo" id="antecipada" name="antecipada" value="ANTECIPADA" :disabled="true" />
                                <label for="antecipada" class="ml-2">Antecipada (Gera Pré Pedido)</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-12" v-if="dados.tipoFixacaoCodigo!='FIXO_DOLAR'">
                        <div class="field">
                            <div class="flex align-items-center">
                                <RadioButton v-model="dados.tipoFixacaoCodigo" id="embarque" name="embarque" value="EMBARQUE" :disabled="true" />
                                <label for="embarque" class="ml-2">Embarque</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-12" v-if="dados.tipoFixacaoCodigo!='FIXO_DOLAR'">
                        <div class="field">
                            <div class="flex align-items-center">
                                <RadioButton v-model="dados.tipoFixacaoCodigo" id="antecipada-embarque" name="antecipada-embarque" value="ANTECIPADA_EMBARQUE" :disabled="true" />
                                <label for="antecipada-embarque" class="ml-2">Antecipada + Embarque</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-12" v-if="dados.tipoFixacaoCodigo=='FIXO_DOLAR'">
                      <div class="field">
                        <div class="flex align-items-center">
                          <RadioButton v-model="dados.tipoFixacaoCodigo" id="fixo-dolar" name="fixo-dolar" value="FIXO_DOLAR" :disabled="true" />
                          <label for="antecipada-embarque" class="ml-2">Fixo Dólar</label>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-12">
                        <div class="field">
                            <label for="data-fixacao">
                                <strong>Data Fixação</strong>
                            </label>
                            <Calendar
                                v-model="dataFixacao"
                                id="data-fixacao"
                                dateFormat="dd/mm/yy"
                                class="w-full"
                                :class="{ 'p-invalid': campoInvalido.dataFixacao }"
                                :maxDate="dataLimiteFixacao"
                            />
                        </div>
                    </div>

                    <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-12">
                        <div class="field">
                            <label for="quantidade">
                                <strong>Quantidade</strong>
                            </label>
                            <div class="p-inputgroup">
                                <InputNumber 
                                    v-model="quantidade"
                                    id="quantidade"
                                    locale="pt-BR"
                                    :max="9999999"
                                    :minFractionDigits="2"
                                    :maxFractionDigits="2"
                                    class="w-full"
                                    :class="{ 'p-invalid': campoInvalido.quantidade }"
                                    @blur="alterarQuantidade"
                                />
                                <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-12">
                        <div class="field">
                            <label for="ingredient3">&nbsp;</label>
                            <Button label="Calcular" class="p-button-info" @click="calcularValorFixacao()" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fieldset>

    <div class="grid p-fluid formgrid align-items-end mt-3 borda-personalizada">

        <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-2">
            <div class="field">
                <div class="flex align-items-center">
                    <label class="mr-2">
                        <strong>Quilo:</strong>
                    </label>
                    <div class="p-inputgroup">
                        <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
                        <InputText v-model="quilo" class="w-full" :disabled="true" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-2">
            <div class="field">
                <div class="flex align-items-center">
                    <label class="mr-2">
                        <strong>Saca&nbsp;60Kg:</strong>
                    </label>
                    <div class="p-inputgroup">
                        <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
                        <InputText v-model="saca60Kg" class="w-full" :disabled="true" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
            <div class="field">
                <div class="flex align-items-center">
                    <label class="mr-2">
                        <strong>Tonelada:</strong>
                    </label>
                    <div class="p-inputgroup">
                        <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
                        <InputText v-model="tonelada" class="w-full" :disabled="true" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-6 md:col-3 lg:col-3 xl:col-3">
            <div class="field">
                <div class="flex align-items-center">
                    <label class="mr-2">
                        <strong>Valor&nbsp;da&nbsp;Fixação:</strong>
                    </label>
                    <div class="p-inputgroup">
                        <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
                        <InputText v-model="valorFixacao" class="w-full" :disabled="true" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-2">
            <div class="field">
                <Button label="Iniciar Fixação" class="p-button-info" @click="abrirFinalizacaoFixacao" :disabled="!ativarIniciarFixacao" />
            </div>
        </div>
    </div>

    <ModalFixarCotacaoIndicador
        :indicadores="indicadoresPrincipais"
        :fixacaoPreco="fixacaoPreco"
        :visivel="fixarCotacaoIndicadorDialog"
        :siglaUnidadeMedida="siglaUnidadeMedida"
        :quantidadeContrato="dados.quantidadeContrato"
        :qtdLancadaPorIndicador="qtdLancadaPorIndicador"
        :fixacaoPrecoContratoInternoId="fixacaoPrecoContratoInternoId"
        @fechar="fixarCotacaoIndicadorFechar"
    />
    
    <ModalHistoricoGenerico
        codigoPagina="FIXACAO_PRECO_INDICADOR"
        :id="fixacaoPrecoIndicadorId"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />
    
    <ModalFinalizacaoFixacao
        :dados="fixacaoPrecoCalculada"
        :visivel="finalizacaoFixacaoDialog"
        :siglaUnidadeMedida="siglaUnidadeMedida"
        :contratoCompraVendaId="contratoCompraVendaId"
        @fechar="fecharFinalizacaoFinalizarFixacao"
    />
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import ModalFinalizacaoFixacao from './ModalFinalizacaoFixacao.vue';
import ModalFixarCotacaoIndicador from './ModalFixarCotacaoIndicador.vue';
import CalcularValorFixacaoService from '../../../service/CalcularValorFixacaoService';
import FixacaoPrecoIndicadorService from '../../../service/FixacaoPrecoIndicadorService';

export default {
    name: "FixacaoPrecoGestaoFixacao",
    components: { ModalHistoricoGenerico, ModalFixarCotacaoIndicador, ModalFinalizacaoFixacao },
    props: {
        fixacaoPrecoContratoInternoId: { required: true },
        contratoCompraVendaId: { required: false },
        siglaUnidadeMedida: { required: false },
        dados: { required: false }
    },
    watch: {
        fixacaoPrecoContratoInternoId(id) {
            if(!!id) this.preencherFormulario();
        }
    },
    data() {
        return {
            quantidade: 0,
            dataFixacao: new Date(),
            dataLimiteFixacao: new Date(),
            tonelada: 0,
            saca60Kg: 0,
            quilo: 0,
            valorFixacao: 0,
            simboloMoeda: "R$",
            indicadoresPrincipais: [],
            indicadores: {} ,
            fixacoesDePreco: [],

            fixacaoPreco: null,
            fixacaoPrecoIndicadorId: null,
            fixarCotacaoIndicadorDialog: false,

            selecionado: {},
            historicoDialog: false,
            descricaoHistoricoDialog: null,
            
            finalizacaoFixacaoDialog: false,

            ativarIniciarFixacao: false,
            fixacaoPrecoCalculada: {},

            campoInvalido: {},

            qtdLancadaPorIndicador: {}
        }
    },
    methods: {
        preencherFormulario() {
            FixacaoPrecoIndicadorService.listarDaFixacaoPrecoContratoInterno(this.fixacaoPrecoContratoInternoId)
                .then(({ data }) => {
                    this.indicadoresPrincipais = data.itensComposicaoValor.map(item => { 
                        this.indicadores[item.indicadorValor.descricao] = null;
                        const indicador = item.indicadorValor;
                        indicador["itemComposicaoValorId"] = item.id;
                        indicador["altera"] = item.altera;
                        return indicador; 
                    });
                    const fixacoesDePreco = data.fixacoesDePreco.map(item => {
                        item["qtdPendente"] = item.quantidade - (item.qtdFixacaoPendente + item.qtdFixada);
                        return item;
                    });

                    this.fixacoesDePreco = fixacoesDePreco.sort((a, b) => {
                        if(a.indicador.sigla < b.indicador.sigla) return -1;
                        if(a.indicador.sigla > b.indicador.sigla) return 1;
                        return 0;
                    })
                });
        },

        alterarQuantidade(event) {
            const { target } = event;
            const value = parseFloat(target.value);
            
            for(let item of this.fixacoesDePreco) {
                const sigla = item.indicador.sigla;
                if(!!this.indicadores[sigla] && value <= item.qtdPendente) {
                    this.indicadores[sigla] = this.indicadores[sigla];
                }
                else {
                    this.indicadores[sigla] = null;
                }
            }

            this.ativarIniciarFixacao = false;
            this.fixacaoPrecoCalculada = {};
            this.tonelada = 0;
            this.saca60Kg = 0;
            this.quilo = 0;
            this.valorFixacao = 0;
        },

        visualizarHistorico(data) {
            this.historicoDialog = true;
            this.fixacaoPrecoIndicadorId = data.id;
            this.descricaoHistoricoDialog = "Indicador: " + data["indicador"].sigla;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.fixacaoPrecoIndicadorId = null;
        },

        fixarCotacaoIndicadorFechar() {
            this.preencherFormulario();
            this.fixacaoPreco = {};
            this.fixacaoPrecoIndicadorId = null;
            this.fixarCotacaoIndicadorDialog = false;
        },

        calcularQuantidadeLancadaPorInidicador() {
            const qtdPorIndicador = {};

            for(let item of this.fixacoesDePreco) {
                const sigla = item.indicador.sigla;
                if (qtdPorIndicador[sigla]) {
                    qtdPorIndicador[sigla] += item.quantidade;
                } else {
                    qtdPorIndicador[sigla] = item.quantidade;
                }
            }

            this.qtdLancadaPorIndicador = qtdPorIndicador;
        },

        fixarCotacaoIndicadorNovo() {
            this.calcularQuantidadeLancadaPorInidicador();
            this.fixacaoPreco = {};
            this.fixacaoPrecoIndicadorId = null;
            this.fixarCotacaoIndicadorDialog = true;
        },

        fixarCotacaoIndicadorEditar(obj) {
            this.calcularQuantidadeLancadaPorInidicador();
            this.fixacaoPreco = obj;
            this.fixarCotacaoIndicadorDialog = true;
        },

        fixarCotacaoIndicadorAtivar(id) {
            FixacaoPrecoIndicadorService.ativar(id)
                .then(() => {
                    this.preencherFormulario();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Ativada',
                        detail: `A fixação de preço do indicador foi ativada com sucesso.`, 
                        life: 14000
                    });
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao ativar',
                        detail: `Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!`, 
                        life: 14000
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        fixarCotacaoIndicadorInativar(id) {
            FixacaoPrecoIndicadorService.inativar(id)
                .then(() => {
                    this.preencherFormulario();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Inativada',
                        detail: `A fixação de preço do indicador foi inativada com sucesso.`, 
                        life: 14000
                    });
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao inativar',
                        detail: `Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!`, 
                        life: 14000
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        abrirFinalizacaoFixacao() {
            this.finalizacaoFixacaoDialog = true;
        },

        fecharFinalizacaoFinalizarFixacao() {
            this.finalizacaoFixacaoDialog = false;
            this.preencherFormulario();
        },

        validarCamposParaCalcular() {
            const campos = [];

            if(!this.dataFixacao) {
                campos.push("Data Fixação");
                this.campoInvalido["dataFixacao"] = true;
            }
            else {
                this.campoInvalido["dataFixacao"] = false;
            }

            if(!this.quantidade) {
                campos.push("Quantidade");
                this.campoInvalido["quantidade"] = true;
            }
            else {
                this.campoInvalido["quantidade"] = false;
            }

            const indicadores = Object.assign({}, this.indicadores);
            const indicadoresNaoSelecionados = Object.keys(indicadores).filter(item => indicadores[item] === null);
            if(indicadoresNaoSelecionados.length > 0) {
                campos.push("Indicador");
                this.campoInvalido["fixar"] = true;
            }
            else {
                this.campoInvalido["fixar"] = false;
            }

            if(campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${ campos[0] }.`;

                if(campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível calcular.',
                    detail: mensagem, 
                    life: 15000
                });
            }

            return campos.length > 0;
        },

        calcularValorFixacao() {
            if(this.validarCamposParaCalcular()) return;

            const obj = {};
            obj["fixacaoPrecoContratoInternoId"] = this.fixacaoPrecoContratoInternoId;
            obj["dataFixacao"] = Formatacao.convertDateToString(this.dataFixacao, "YYYY-MM-DD HH:mm:ss");
            obj["quantidade"] = this.quantidade;

            const indicadores = Object.assign({}, this.indicadores);
            obj["fixacoesIndicador"] = Object.keys(indicadores).map(item => { return {id: indicadores[item]}; });

            CalcularValorFixacaoService.calcularValorFixacao(obj)
                .then(({ data }) => {                    
                    const valorQuilo = data.valorQuilo;

                    this.ativarIniciarFixacao = true;
                    this.tonelada = Formatacao.formatDecimal(data.valorTonelada, 4, 4);
                    this.saca60Kg = Formatacao.formatDecimal(data.valorSaca, 4, 4);
                    this.quilo = Formatacao.formatDecimal(data.valorQuilo, 4, 4);

                    let valorTotal = 0;
                    if(this.dados.unidade == "TONELADA"){
                        valorTotal = (valorQuilo * 1000) * this.quantidade;
                    }else if(this.dados.unidade == "SACA"){
                        valorTotal = (valorQuilo * 60) * this.quantidade;
                    }else{
                        valorTotal = valorQuilo * this.quantidade;
                    }
                    data.valorTotal = valorTotal;
                    this.valorFixacao = Formatacao.formatDecimal(valorTotal, 2, 2);
                    this.fixacaoPrecoCalculada = data;
                    this.fixacaoPrecoCalculada["dataFixacao"] = Formatacao.convertStringToDate(data.dataFixacao, "YYYY-MM-DD HH:mm:ss");
                });
        },

        convertDateToString(data, formato) {
            return Formatacao.convertDateToString(data, formato);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format) {
            return Formatacao.formatDateCustom(data, format);
        }
    }
}
</script>

<style scoped>
    .borda-personalizada {
        border: 1px solid #E0E0E0;
        border-radius: 3px;
        padding: 1rem;
        margin: 0.1rem;
    }

    .label {
        text-align: right;
        padding: 0;
        margin: 0.5rem 0;
        padding: 0 0 0 0.5rem;
    }

    .campo {
        margin: 0.5rem 0;
        padding: 0 0 0 0.5rem;
        overflow: hidden;
    }
</style>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    
    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }

    .tamanho-botao-tabela {
        height: 30px !important;
        width: 30px !important;
    }

    .valor-campo-numerico{
        text-align: right; 
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
        width: 70px !important; 
        font-weight: 600;
    }
</style>
