<template>
  <div class="flex align-items-center">
    <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label><i class="pi pi-money-bill mr-2" style="font-size: 1.5rem;"></i>
    <strong style="font-size: 13pt; color: #2f3030;">Forma de Pagamento</strong>
  </div>

  <Fieldset legend="" v-model:editingRows="editingRows" >
    <DataTable :value="ccv.adiantamentos" showGridlines>
      <template #header>
        <div class="flex align-items-center justify-content-between">
          <strong style="font-size: 14px;">Adiantamento</strong>
        </div>
      </template>

      <Column header="Data">
        <template #body="{ data }">
          {{ formatDateCustom(data.dataAdiantamento, "DD/MM/YYYY") }}
        </template>
      </Column>

      <Column header="Quantidade">
        <template #body="{ data }">
          {{ formatDecimal(data.quantidade) }}
        </template>
      </Column>

      <Column header="Unidade">
        <template #body="{ data }">
          {{ data.unidade }}
        </template>
      </Column>

      <Column header="Preço Unitário">
        <template #body="{ data }">
          R$ {{ formatDecimal(data.precoUnitario) }}
        </template>
      </Column>

      <Column header="Valor">
        <template #body="{ data }">
          R$ {{ formatDecimal(data.valorTotal) }}
        </template>
      </Column>

      <template #footer>
        Quantidade Total: {{ formatDecimal(atiandamentoQuantidadeTotal) }} Ton. - Total Adiantamento: R$ {{ formatDecimal(valorAdiantamentoOut )}}
      </template>
    </DataTable><br>

        <div class="flex flex-wrap gap-3 p-fluid formgrid">
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton :disabled="true" v-model="dados.tipoFormaPagamento" id="no-dia" value="NO_DIA" />
                <label for="no-dia" class="ml-2">
                    <strong>No Dia</strong>
                </label>
            </div>
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton :disabled="true" v-model="dados.tipoFormaPagamento" id="numero-dias-fixacao" value="NUMERO_DIAS_FIXACAO" />
                <label for="numero-dias-fixacao" class="ml-2">
                    <strong>Nº Dias da Fixação</strong>
                </label>
            </div>
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton :disabled="true" v-model="dados.tipoFormaPagamento" id="numero-dias-embarque" value="NUMERO_DIAS_EMBARQUE" />
                <label for="numero-dias-embarque" class="ml-2">
                    <strong>Nº Dias do Embarque</strong>
                </label>
            </div>
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton :disabled="true" v-model="dados.tipoFormaPagamento" id="numero_dias_desembarque" value="NUMERO_DIAS_DESEMBARQUE" />
                <label for="numero_dias_desembarque" class="ml-2">
                    <strong>Nº Dias do Desembarque</strong>
                </label>
            </div>
            <div class="flex align-items-center mr-3 mt-2 mb-2">
                <RadioButton :disabled="true" v-model="dados.tipoFormaPagamento" id="parcelas" value="PARCELAS" />
                <label for="parcelas" class="ml-2">
                    <strong>Parcelas</strong>
                </label>
            </div>

            <div class="flex align-items-center mr-4" v-if="tipo != null && dados.tipoFormaPagamento.includes('NUMERO_DIAS')">
                <label class="mr-2" for="nro-dias">Nº Dias:</label>
                <InputNumber v-model="numeroDias" id="nro-dias" style="width: 100px;"/>
            </div>

            <div class="flex align-items-center mr-4" v-if="dados.tipoFormaPagamento == 'NO_DIA'">
                <label class="mr-2" for="data_vencimento">Data Vencimento:</label>
                <InputText :disabled="true" v-model="dados.noDia" id="data_vencimento" style="width: 100px;"/>
            </div>
        </div>

      <ParcelasForm
          :editavel="false" :dto="dados.parcelas" :ccv="ccv" :totalAdiantamento="0" :campo-invalido="campoInvalido"
      />

    </Fieldset>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import ParcelasForm from "../../../components/cadastro/contrato/ParcelasForm.vue";

export default {
    components: {ParcelasForm},
    name: "ModalFinalizacaoFixacaoFormaPagamento",
    props: {
        visivel: {
            type: Boolean,
            required: true
        },
        dados: {
          type: Object,
          required: true
        },
        ccv: {
          type: Object,
          required: true
        },
        campoInvalido: {
          type: Object,
          required: true
        }
    },
    data() {
        return {
            tipo: null,
            numeroDias: null,
            dataVencimento: null,
            parcelas: [
                {
                    data: new Date(),
                    percentual: 10.5,
                    valorTotal: 10000
                },
                {}
            ],
            editingRows: {}
        }
    },
    created() {
      let a = 0;
      },

  methods: {
        editar(registro) {
            const registroAux = Object.assign({}, registro);
            this.editingRows = [registroAux];
        },

        excluir(indice) {
            this.parcelas.splice(indice, 1);
            this.adicionarLinha();
        },

        salvar(registro, indice) {
            if(!registro) return;
            this.parcelas[indice] = registro;
            this.editingRows = [];
            this.adicionarLinha();
        },

        cancelar() {
            if(!this.editingRows && this.editingRows.length <= 0) return;
            this.editingRows = [];
            this.adicionarLinha();
        },

        adicionarLinha() {
            const indice = this.parcelas.findIndex(item => { return !item.data || !item.percentual });
            if(indice < 0) this.parcelas.push({});
        },

        convertDateToString(data, formato) {
            return Formatacao.convertDateToString(data, formato);
        },
        
        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format) {
            return Formatacao.formatDateCustom(data, format);
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    
    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
