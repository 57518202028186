import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class FixacaoPrecoIndicadorService {
    buscarComPaginacao(params) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-indicador/buscar-com-paginacao`, { params });
    }

    listarDaFixacaoPrecoContratoInterno(fixacaoPrecoContratoInternoId) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-indicador/buscar/indicadores-fixacao-preco-contrato-interno/${ fixacaoPrecoContratoInternoId }`);
    }

    buscarPorId(id) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-indicador/${ id }`);
    }

    salvar(dados) {
        const config = {
            headers: { 'content-type': 'application/json' }
        }
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-indicador`, dados, config);
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-indicador/inativar/${ id }`);
    }

    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-indicador/ativar/${ id }`);
    }
}

export default new FixacaoPrecoIndicadorService();