<template>
    <Dialog maximizable modal v-model:visible="visivel" :style="{width: '95rem', maxWidth: '1500px' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" @hide="fechar()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <h4 style="margin:0;">Fixação de Preço</h4>
            </div> 
        </template>

        <div class="borda-personalizada">
            <Timeline :value="itensDoProcesso" layout="horizontal" align="top">
                <template #marker="{ item, index }">
                    <span class="flex align-items-center">
                        <span class="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1 mr-2 ml-1" :class="{ 'timeline-indicador-ativa': index <= etapaAtual(),  'timeline-indicador-inativa': index > etapaAtual()}">
                            <strong>{{  index + 1 }}</strong>
                        </span>
        
                        <span class="mr-2" :class="{ 'timeline-descricao-ativa': index <= etapaAtual(), 'timeline-descricao-inativa': index > etapaAtual() }">
                            {{ item.label.replaceAll(" ", "&nbsp;") }}
                        </span>
                    </span>
                </template>
            </Timeline>

            <Cabecalho :dados="dados" :valorNotaComplementar="valorNotaComplementar" :siglaUnidadeMedida="siglaUnidadeMedida" :etapaFinal="etapaAtual()==4" />
            
            <Message v-for="msg of mensagens" :severity="msg.gravidade" :sticky="false" :closable="msg.fechar" :life="msg.duracao">{{ msg.conteudo }}</Message>

          <ScrollPanel style="width: 100%; height: 350px; border: solid 1px #cecece" >
            <IndicadoresSelecionados v-if="etapaAtual() == 0 || etapaAtual() == 4" :dados="dados.indicadores" :etapaFinal="etapaAtual()==4" class="pb-8"/>
            <PedidosContrato v-if="etapaAtual() == 1 || (etapaAtual() == 4 && !notasFixadas)" :dados="pedidosFixados" :loading="loadingPedidos" :siglaUnidadeMedida="siglaUnidadeMedida" :etapaFinal="etapaAtual()==4" class="pb-8" />
            <NotasFiscais v-if="etapaAtual() == 2 || etapaAtual() == 4" :dados="notasFixadas" :loading="loadingNotas" :cabecalho="dados" :siglaUnidadeMedida="siglaUnidadeMedida" :etapaFinal="etapaAtual()==4" class="pb-8"/>
            <FormaPagamento :ccv="ccv" :dados="ccv.formaPagamento" v-if="etapaAtual() == 3 || etapaAtual() == 4" />
          </ScrollPanel>

        </div>

      <div class="flex mt-3">
            <Button
                v-if="etapaAtual() == etapas.PROVISIONAR"
                icon="pi pi-save"
                label="Salvar"
                class="p-button-info mr-2"
                @click="salvar"
            ></Button>

            <Button
                v-if="etapaAtual() > etapas.PROVISIONAR"
                icon="pi pi-arrow-left"
                label="Retornar"
                class="p-button-outlined p-button-info mr-2"
                @click="voltarEtapa"
            ></Button>

            <Button
                icon="pi pi-times"
                label="Cancelar"
                class="p-button-outlined p-button-danger mr-2"
                @click="fechar"
            ></Button>

            <Button
                v-if="etapaAtual() < etapas.FORMA_PAGAMENTO"
                icon="pi pi-arrow-right"
                label="Continuar"
                class="p-button-info mr-2"
                @click="avancarEtapa"
            ></Button>

            <Button
                v-if="etapaAtual() == etapas.FORMA_PAGAMENTO"
                icon="pi pi-dollar"
                label="Fixar Preço"
                class="p-button-info mr-2"
                @click="avancarEtapa"
            ></Button>

            <Button
                v-if="etapaAtual() == etapas.CONFIRMACAO"
                icon="pi pi-sign-out"
                label="Concluir Fixação"
                class="p-button-info mr-2"
                @click="concluirFixacaoDialog=true"
            ></Button>
        </div>
    </Dialog>

    <Dialog v-model:visible="concluirFixacaoDialog" :style="{ width: '500px' }" header="Alerta" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
        <span v-if="valorNotaComplementar>0">Será gerada uma Nota Fiscal Complementar no valor de R$ {{formatDecimal(valorNotaComplementar)}}<br><br>
        Tem certeza que deseja concluir a Fixação de Preço?</span>
        <span v-if="valorNotaComplementar==0">Tem certeza que deseja concluir a Fixação de Preço?</span>

      </div>
      <template #footer>
        <Button label="Não" icon="pi pi-times" class="p-button-text" @click="concluirFixacaoDialog = false" />
        <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="concluirFixacao" />
      </template>
    </Dialog>

</template>

<script>
import Cabecalho from './ModalFinalizacaoFixacaoCabecalho.vue';
import NotasFiscais from './ModalFinalizacaoFixacaoNotasFiscais.vue';
import FormaPagamento from './ModalFinalizacaoFixacaoFormaPagamento.vue';
import PedidosContrato from './ModalFinalizacaoFixacaoPedidosContrato.vue';
import IndicadoresSelecionados from './ModalFinalizacaoFixacaoIndicadoresSelecionados.vue';
import FixacaoPrecoFinalizacaoService from '../../../service/FixacaoPrecoFinalizacaoService';
import Formatacao from '../../../utilities/Formatacao';
import TratamentoDeError from '../../../utilities/TratamentoDeError';
import ContratoCompraVendaService from "@/service/ContratoCompraVendaService";

export default {
    name: "ModalFinalizacaoFixacao",
    components: { Cabecalho, IndicadoresSelecionados, PedidosContrato, NotasFiscais, FormaPagamento },
    emits: ["fechar"],
    props: {
        dados: { required: false },
        ccv: {required: false},
        visivel: { required: true },
        contratoCompraVendaId: { required: false },
        siglaUnidadeMedida: { required: true }
    },
    watch: {
        visivel(param) {
            if(!param) return;
            this.historicoEtapas = [0];
            this.mensagens = [];
        }
    },
    data() {
        return {
            historicoEtapas: [0],
            etapas: {
                PROVISIONAR: 0,
                FIXAR_PEDIDOS: 1,
                FIXAR_NOTAS: 2,
                FORMA_PAGAMENTO: 3,
                CONFIRMACAO: 4
            },

            itensDoProcesso: [
                { label: 'Provisionar' },
                { label: 'Fixar Pedidos' },
                { label: 'Fixar Notas' },
                { label: 'Forma de Pagamento' },
                { label: 'Confirmação' }
            ],

            loadingPedidos: true,
            pedidosFixados: {},

            loadingNotas: true,
            notasFixadas: {},
            notaComplementar: {valorNota:0},
            valorNotaComplementar: 0,
            ccv: {},

            mensagens: [],
            concluirFixacaoDialog: false
        }
    },
    methods: {
        etapaAtual() { return this.historicoEtapas[this.historicoEtapas.length - 1]; },
        adicionarEtapa(indice) { this.historicoEtapas.push(indice); },
        voltarEtapa() { this.historicoEtapas.pop(); },
        avancarEtapa() {
            if(this.validarProximaEtapa()) return;
            if(this.etapaAtual() === this.etapas["FIXAR_PEDIDOS"]) this.buscarPedidos();
            if(this.etapaAtual() === this.etapas["FIXAR_NOTAS"]) this.buscarNotasFiscais();
            if(this.etapaAtual() === this.etapas["FORMA_PAGAMENTO"]) this.buscarNotaComplementar();
        },

        validarProximaEtapa() {
            const etapaAtual = this.etapaAtual();
            let proximaEtapa = this.etapas["FIXAR_PEDIDOS"];

            if(etapaAtual === this.etapas["FIXAR_PEDIDOS"]) {
                proximaEtapa = this.validarEtapaFixarPedidos();
            }
            else if(etapaAtual === this.etapas["FIXAR_NOTAS"]) {
                proximaEtapa = this.validarEtapaFixarNotas();
            }
            else if(etapaAtual === this.etapas["FORMA_PAGAMENTO"]) {
                proximaEtapa = this.etapas["CONFIRMACAO"];
            }

            if(proximaEtapa === etapaAtual) return true;
            this.adicionarEtapa(proximaEtapa);
            return false;
        },

        validarEtapaFixarPedidos() {
            const pedidos = this.pedidosFixados.pedidos.filter(item => item.selecionado);
            const qtdTotalFixacaoPedidos = this.totalizador(pedidos, "quantidadeFixacao");
            const saldoTotalFixacaoPedidos = this.totalizador(this.pedidosFixados.pedidos, "saldoFixacaoPedido");

            if(saldoTotalFixacaoPedidos === 0) {
                return this.etapas["FORMA_PAGAMENTO"];
            }
            else if(qtdTotalFixacaoPedidos === 0 && saldoTotalFixacaoPedidos > 0) {
                this.mensagens.push({
                    gravidade: "warn", duracao: 7500, fechar: false,
                    conteudo: "Por favor, informe a quantidade de fixação em pelo menos um dos pedidos."
                });

                return this.etapas["FIXAR_PEDIDOS"];
            }
            else if(qtdTotalFixacaoPedidos < this.dados.quantidade && saldoTotalFixacaoPedidos > qtdTotalFixacaoPedidos) {
                this.mensagens.push({
                    gravidade: "warn", duracao: 7500, fechar: false,
                    conteudo: "Para continuar, é necessário informar a quantidade total fixada."
                });

                return this.etapas["FIXAR_PEDIDOS"];
            }
            else if(qtdTotalFixacaoPedidos > this.dados.quantidade) {
                this.mensagens.push({
                    gravidade: "warn", duracao: 7500, fechar: false,
                    conteudo: "A quantidade total de fixação dos pedidos não pode ser maior que a quantidade fixada."
                });

                return this.etapas["FIXAR_PEDIDOS"];
            }

            return this.etapas["FIXAR_NOTAS"];
        },

        validarEtapaFixarNotas() {
            let proximaEtapa = this.etapas["FORMA_PAGAMENTO"];
            this.notasFixadas.pedidos.forEach(item => {
                const faturamentos = item.faturamentos;
                const qtdTotalFixacaoNf = this.totalizador(faturamentos, "quantidadeFixacao");
                const saldoTotalFixacaoNf = this.totalizador(faturamentos, "saldoFixacaoFaturamento");
                const qtdTotalFixacaoPedidoEmKg = Formatacao.converterEmQuilos(item.quantidadeFixacao, item.unidade);

                console.log(qtdTotalFixacaoNf, saldoTotalFixacaoNf, qtdTotalFixacaoPedidoEmKg)
                if(qtdTotalFixacaoNf === 0 && saldoTotalFixacaoNf > 0) {
                    this.mensagens.push({
                        gravidade: "warn", duracao: 7500, fechar: false,
                        conteudo: `Informe a quantidade da fixação em pelo menos uma das notas fiscais do pedido ${ item.pedidoId }.`
                    });

                    proximaEtapa = this.etapas["FIXAR_NOTAS"];
                }
                else if(qtdTotalFixacaoNf < qtdTotalFixacaoPedidoEmKg && saldoTotalFixacaoNf > qtdTotalFixacaoNf) {
                    this.mensagens.push({
                        gravidade: "warn", duracao: 7500, fechar: false,
                        conteudo: `É necessário informar a quantidade total fixada no pedido ${ item.pedidoId }.`
                    });

                    proximaEtapa = this.etapas["FIXAR_NOTAS"];
                }
                else if(qtdTotalFixacaoNf > qtdTotalFixacaoPedidoEmKg) {
                    this.mensagens.push({
                        gravidade: "warn", duracao: 7500, fechar: false,
                        conteudo: `A quantidade total da fixação nas notas fiscais não pode ser maior que a quantidade fixada no pedido ${ item.pedidoId }.`
                    });

                    proximaEtapa = this.etapas["FIXAR_NOTAS"];
                }
            });

            return proximaEtapa;
        },

        fechar() {
            this.$emit("fechar");
        },

        salvar() {
            const dados = Object.assign({}, this.dados);
            dados["dataFixacao"] = Formatacao.convertDateToString(dados.dataFixacao, "YYYY-MM-DD HH:mm:ss");

            FixacaoPrecoFinalizacaoService.salvar(dados)
                .then(({ data }) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Fixação salva.',
                        detail: `A fixação foi salva com sucesso!`, 
                        life: 14000
                    });
                    this.fechar();
                })
                .catch(error => {
                    this.$toast.add(TratamentoDeError(error, !!this.dados.id));
                });
        },

        totalizador(dados, campo) {
            if(dados == null || dados == undefined) return 0;
            const total = dados.reduce((valorAcumulado, item) => valorAcumulado + item[campo], 0);
            return isNaN(total) ? 0 : total;
        },

        buscarPedidos() {
            this.loadingPedidos = true;
            this.pedidosFixados = {};
            const dados = {
                contratoCompraVendaId: this.contratoCompraVendaId,
                qtdTotalFixacao: this.dados.quantidade
            };

          ContratoCompraVendaService.buscarPorId(this.dados.nrCi)
              .then(({ data }) => {
                this.ccv = data;
              });

            FixacaoPrecoFinalizacaoService.buscarPedidos(dados)
                .then(({ data }) => {
                    this.loadingPedidos = false;
                    this.pedidosFixados = data;
                    if(!data.pedidos || data.pedidos.length === 0) {
                        this.mensagens.push({
                            gravidade: "info", duracao: 7500, fechar: true,
                            conteudo: "Não foi encontrado pedidos."
                        });
                    }
                })
                .catch(error => {
                    this.loadingPedidos = false;
                    const mensagem = {
                        gravidade: "error", duracao: 7500, fechar: true,
                        conteudo: "Ocorreu uma falha não mapeada ao consultar notas fiscais."
                    }

                    if(!!error.response && !!error.response.data && !!error.response.data.message) {
                        mensagem["conteudo"] = error.response.data.message;
                    }

                    this.mensagens.push(mensagem);
                });
        },

        buscarNotasFiscais() {
            this.loadingNotas = true;
            this.notasFixadas = {};
            const dados = {
                contratoCompraVendaId: this.contratoCompraVendaId,
                qtdTotalFixacao: this.dados.quantidade,
                pedidos: this.pedidosFixados.pedidos.filter(item => item.selecionado)
            };

            FixacaoPrecoFinalizacaoService.buscarNotasFiscais(dados)
                .then(({ data }) => {
                    this.loadingNotas = false;
                    this.notasFixadas = data;
                    if(!data.pedidos || data.pedidos.length === 0) {
                        this.mensagens.push({
                            gravidade: "info", duracao: 7500, fechar: true,
                            conteudo: "Não foi encontrado nota fiscais referente os pedidos selecionados."
                        });
                    }
                })
                .catch(error => {
                    this.loadingNotas = false;
                    const mensagem = {
                        gravidade: "error", duracao: 7500, fechar: true,
                        conteudo: "Ocorreu uma falha não mapeada ao consultar notas fiscais."
                    }

                    if(!!error.response && !!error.response.data && !!error.response.data.message) {
                        mensagem["conteudo"] = error.response.data.message;
                    }

                    this.mensagens.push(mensagem);
                });
        },

        buscarNotaComplementar() {
          this.notaComplementar = {};
          const dados = {
            valorPorQuilo: this.dados.valorQuilo,
            pedidos: this.notasFixadas.pedidos.filter(item => item.selecionado)
          };

          FixacaoPrecoFinalizacaoService.buscarNotaComplementar(dados)
              .then(({ data }) => {
                this.notaComplementar = data;
                this.valorNotaComplementar = this.calculaValorNota(data);
              })
              .catch(error => {
                const mensagem = {
                  gravidade: "error", duracao: 7500, fechar: true,
                  conteudo: "Ocorreu uma falha não mapeada ao consultar nota complementar."
                }

                if(!!error.response && !!error.response.data && !!error.response.data.message) {
                  mensagem["conteudo"] = error.response.data.message;
                }

                this.mensagens.push(mensagem);
              });
        },

        concluirFixacao() {
          const obj = this.montaJson();

          FixacaoPrecoFinalizacaoService.concluirFixacao(obj)
              .then(({ data }) => {
                this.$router.push(`/fixacao-preco`);

              })
              .catch(error => {
                    this.$toast.add(TratamentoDeError(error, !!this.dados.id));
                });
            this.concluirFixacaoDialog = false;
        },

        montaJson(){
          const obj = {};

          const pedidos = this.notasFixadas.pedidos.filter(item => item.selecionado);
          const notaComplementar = this.notaComplementar;

          for(let pedido of pedidos){
            for(let nota of notaComplementar){
              if(nota.pedidoId==pedido.pedidoId){
                pedido.complemento = nota.complemento;
              }
            }
          }

          const dados = this.dados;
          dados.dataFixacao = Formatacao.convertDateToString(dados.dataFixacao, "YYYY-MM-DD HH:mm:ss");
          obj["fixacaoPreco"] = dados;

          obj["pedidos"] = pedidos;
          return obj;
        },

        calculaValorNota(notaComplementar){
            let valor = 0;

            for(let nota of notaComplementar){
              if(!!nota.complemento) {
                valor = valor + nota.complemento.valorNota;
              }
          }
          return valor;
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
          if (value!=null) return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

    }
}
</script>

<style>
    .p-timeline-event-content {
        width: 0 !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .p-timeline-event-opposite {
        width: 0 !important;
        height: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    }
</style>

<style scoped>
    .borda-personalizada {
        border: 1px solid #E0E0E0;
        border-radius: 3px;
        padding: 1rem;
        margin: 0.1rem;
    }

    .timeline-indicador-ativa {
        background-color: #3f51b5;
    }

    .timeline-descricao-ativa {
        color:#000;
    }

    .timeline-indicador-inativa {
        background-color: #6c7085;
    }

    .timeline-descricao-inativa {
        color:#6c7085;
    }
</style>
