import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class FixacaoPrecoFinalizacaoService {
    salvar(dados) {
        const config = {
            headers: { 'content-type': 'application/json' }
        }
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco`, dados, config);
    }

    buscarPedidos(dados) {
        const config = { headers: { 'content-type': 'application/json' } };
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-pedido/buscar-pendentes`, dados, config);
    }

    buscarNotasFiscais(dados) {
        const config = { headers: { 'content-type': 'application/json' } };
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-faturamento/buscar-pendentes`, dados, config);
    }

    buscarNotaComplementar(dados) {
        const config = { headers: { 'content-type': 'application/json' } };
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/conclusao-fixacao-preco/preparar-nota-complementar`, dados, config);
    }

    concluirFixacao(dados) {
        const config = { headers: { 'content-type': 'application/json' } };
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/conclusao-fixacao-preco/concluir`, dados, config);
    }

}

export default new FixacaoPrecoFinalizacaoService();