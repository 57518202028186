<template>
    <DataTable :value="fixacoes" showGridlines>
        <template #header>
            <div class="flex align-items-center justify-content-between">
                <strong>Informações da Fixação/Pagamento</strong>

                <Button
                    v-if="situacao == 'ATIVO'"
                    type="button"
                    icon="pi pi-ban"
                    label="Listar&nbsp;inativos"
                    class="p-button-outlined p-button-danger"
                    @click="situacao = 'INATIVO'; carregarFixacoes(); carregarTotalizadores();"
                ></Button>

                <Button
                    v-if="situacao == 'INATIVO'"
                    type="button"
                    icon="pi pi-check-circle"
                    label="Listar&nbsp;ativos"
                    class="p-button-outlined"
                    @click="situacao = 'ATIVO'; carregarFixacoes(); carregarTotalizadores();"
                ></Button>
            </div>
        </template>

        <Column field="numeroPorContrato" header="Número" style="width:8rem;" />
        <Column field="id" header="ID" style="width:8rem;" />
        <Column field="descricaoRegra" header="Regra" />
        <Column field="quantidade" header="Quantidade" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field], 2, 2) }} {{ siglaUnidadeMedida }}
            </template>
        </Column>

        <Column field="valorQuilo" header="Vl. Unit. Fixado" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field], 4, 4) }}
            </template>
        </Column>

        <Column field="percentual" header="Percentual" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field]) }}&nbsp;%
            </template>
        </Column>

        <Column field="valorTotal" header="Valor Total" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                R$&nbsp;{{ formatDecimal(data[field]) }}
            </template>
        </Column>

        <Column field="status" header="Situação" style="width:10rem;">
            <template #body="{ data, field }">
               {{ data[field]?.descricao }}
            </template>
        </Column>

        <Column header="Ações" style="width:10rem;" class="centralizar-titulo-tabela">
            <template #body="{ data }">
                <div class="flex align-items-center justify-content-center">
                    <Button 
                        title="Visualizar histórico"
                        icon="pi pi-list"
                        class="p-button-rounded p-button-warning tamanho-botao-tabela flex-none mr-2"
                        :disabled="data.id == null || data.id == undefined"
                        @click="visualizarHistorico(data)"
                    ></Button>

                    <Button
                        class="p-button-rounded p-button-info tamanho-botao-tabela flex-none mr-2"
                        icon="pi pi-pencil"
                        title="Editar"
                        :disabled="!data.ativo || data.status.codigo == 'CONCLUIDO'"
                        @click="editarFinalizacaoFixacao(data)"
                    ></Button>

                    <Button 
                        v-if="!data.ativo"
                        title="Ativar"
                        class="p-button-rounded p-button-info tamanho-botao-tabela flex-none"
                        icon="pi pi-check-circle"
                        @click="fixarCotacaoIndicadorAtivar(data.id)"
                        :disabled="data.status.codigo == 'CONCLUIDO'"
                    ></Button>
                    
                    <Button 
                        v-if="data.ativo"
                        class="p-button-rounded p-button-danger tamanho-botao-tabela flex-none"
                        icon="pi pi-ban"
                        title="Inativar"
                        :disabled="data.id == null || data.id == undefined || data.status.codigo == 'CONCLUIDO'"
                        @click="fixarCotacaoIndicadorInativar(data.id)"
                    ></Button>
                </div>
            </template>
        </Column>

        <ColumnGroup type="footer">
            <Row>
                <Column footer="Total Geral:" :colspan="2" footerStyle="text-align:right" />
                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(qtdKgTotal, 2, 2) }} {{ siglaUnidadeMedida }}
                    </template>
                </Column>
                <Column />

                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(percentualTotal) }}&nbsp;%
                    </template>
                </Column>

                <Column footerStyle="text-align:right">
                    <template #footer>
                        R$&nbsp;{{ formatDecimal(total) }}
                    </template>
                </Column>
                <Column />
                <Column />
            </Row>
        </ColumnGroup>
    </DataTable>

    <Paginator
        v-model:first="primeiraLinha"
        :rows="qtdRegistroPorPagina"
        :total-records="totalRegistro"
        :rowsPerPageOptions="[5, 10, 20, 30]"
        @page="onPage"
    />

    <ModalHistoricoGenerico
        codigoPagina="FIXACAO_PRECO"
        :id="fixacaoId"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />

    <ModalFinalizacaoFixacao
        :visivel="finalizacaoFixacaoDialog"
        :contratoCompraVendaId="contratoCompraVendaId"
        :dados="fixacao"
        :siglaUnidadeMedida="siglaUnidadeMedida"
        :ccv="ccv"
        @fechar="fecharFinalizacaoFinalizarFixacao"
    />
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import ModalFinalizacaoFixacao from './ModalFinalizacaoFixacao.vue';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import FixacaoPrecoService from '../../../service/FixacaoPrecoService';

export default {
    name: "FixacaoPrecoFixacoes",
    components: { ModalHistoricoGenerico, ModalFinalizacaoFixacao },
    props: {
        fixacaoPrecoContratoInternoId: { required: false },
        contratoCompraVendaId: { required: false },
        contratoInternoId: { required: true },
        quantidadeContrato: { required: true },
        siglaUnidadeMedida: { required: true },
        ccv: { required: true }
    },
    watch: {
        fixacaoPrecoContratoInternoId(id) {
            if(!!id) {
                this.carregarFixacoes();
                this.carregarTotalizadores();
            }
        }
    },
    data() {
        return {
            situacao: 'ATIVO',
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,


            qtdKgTotal: 0,
            percentualTotal: 0,
            total: 0,
            
            fixacoes: [],

            historicoDialog: false,
            descricaoHistoricoDialog: null,
            
            finalizacaoFixacaoDialog: false,
            fixacaoId: null,
            fixacao: {}
        }
    },
    methods: {
        carregarTotalizadores() {
            const params = {};
            params["fixacaoPrecoContratoInternoId"] = this.fixacaoPrecoContratoInternoId;
            params["situacao"] = this.situacao;
            FixacaoPrecoService.buscarTotalGeral(params)
                .then(({ data }) => {
                    this.qtdKgTotal = data.quantidadeTotal;
                    this.percentualTotal = data.percentualFixacao;
                    this.total = data.valorTotal;
                });
        },

        carregarFixacoes() {
            const params = {};
            params["situacao"] = this.situacao;
            params["page"] = this.pagina;
            params["size"] = this.qtdRegistroPorPagina;
            params["sort"] = this.ordenar;
            params["fixacaoPrecoContratoInternoId"] = this.fixacaoPrecoContratoInternoId;
            params["contratoInternoId"] = this.contratoInternoId;

            FixacaoPrecoService.buscarComPaginacao(params)
                .then(({ data }) => {
                    this.qtdRegistroPorPagina = data.pageable.pageSize;
                    this.totalRegistro = data.totalElements;

                    this.fixacoes = data.content.map(item => {
                        const qtdContrato = this.quantidadeContrato;
                        const quantidade = parseFloat(item.quantidade);
                        item["percentual"] = (quantidade / qtdContrato) * 100;
                        return item;
                    });
                });
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        visualizarHistorico(data) {
            this.historicoDialog = true;
            this.fixacaoId = new String(data.id);
            this.descricaoHistoricoDialog = "Indicador: " + data.id;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.fixacaoId = null;
        },

        editarFinalizacaoFixacao(data) {
            this.finalizacaoFixacaoDialog = true;
            FixacaoPrecoService.buscarFixacaoPorId(data.id)
                .then(({ data }) => {
                    const fixacao = data;
                    fixacao["dataFixacao"] = Formatacao.convertStringToDate(fixacao.dataFixacao, "YYY-MM-DD HH:mm:ss");
                    this.fixacao = fixacao;
                });

        },

        fecharFinalizacaoFinalizarFixacao() {
            this.finalizacaoFixacaoDialog = false;
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        fixarCotacaoIndicadorAtivar(id) {
            FixacaoPrecoService.ativar(id)
                .then(() => {
                    this.carregarFixacoes();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Ativada',
                        detail: `A fixação de preço foi ativada com sucesso.`, 
                        life: 14000
                    });
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao ativar',
                        detail: `Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!`, 
                        life: 14000
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        fixarCotacaoIndicadorInativar(id) {
            FixacaoPrecoService.inativar(id)
                .then(() => {
                    this.carregarFixacoes();
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Inativada',
                        detail: `A fixação de preço foi inativada com sucesso.`, 
                        life: 14000
                    });
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao inativar',
                        detail: `Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!`, 
                        life: 14000
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    
    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }

    .tamanho-botao-tabela {
        height: 30px !important;
        width: 30px !important;
    }
</style>
