<template>
    <DataTable :value="dados.pedidos" v-model:expandedRows="expandedRows" :loading="loading" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-file-pdf mr-2" style="font-size: 1.5rem;"></i>
                <strong style="font-size: 13pt; color: #2f3030;">Notas Fiscais</strong>
            </div>
        </template>

        <template #empty>
            Nenhum registro encontrado.
        </template>

        <template #loading>
            Carregando... Por favor, aguarde.
        </template>

        <Column expander style="width: 5rem" />

        <Column field="codigoEstabelecimento" header="Estab." style="width:8rem;">
            <template #body="{ data, field }">
                {{ data[field] }}
            </template>
        </Column>
        
        <Column field="pedidoId" header="Nr. Pedido" style="width:10rem;">
            <template #body="{ data, field }">
                {{ data[field] }}
            </template>
        </Column>
        
        <Column field="dataPedido" header="Data Pedido" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDateCustom(data[field], "DD/MM/YYYY") }}
            </template>
        </Column>
        
        <Column field="quantidadePedido" header="Qt. Pedido" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>
        
        <Column field="quantidadeEmbarque" header="Qt. Embarque" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>
        
        <Column field="saldoPedido" header="Saldo Pedido" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>
        
        <Column field="quantidadeFixacao" header="Qt. Fixação" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>

        <template #expansion="slotProps">
            <DataTable :value="slotProps.data.faturamentos" style="margin-left: 1rem;">
                <template #header>
                    <div class="flex align-items-center">
                        <strong style="font-size: 12pt; color: #2f3030;">Selecione as notas fiscais</strong>
                    </div>
                </template>

                <template #empty>
                    Nenhum registro encontrado.
                </template>

                <Column field="selecionado" class="centralizar-titulo-tabela" style="width:3rem;">
                    <template #body="{ data, field }">
                        <div class="flex align-items-center">
                            <Checkbox v-model="data[field]" id="sim" @click="selecionarNotaFiscal(data, slotProps.data)" :disabled="etapaFinal || bloquearSelecao(data[field], slotProps.data)" binary />
                        </div>
                    </template>
                </Column>
                
                <Column field="codigoItemNota" header="Item" style="width:8rem;"/>
                <Column field="nrNotaFiscal" header="Nota Fiscal" style="width:10rem;"/>
                
                <Column field="valorUnitario" header="Vl. Unit. Faturado" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                    <template #body="{ data, field }">
                        R$&nbsp;{{ formatDecimal(data[field])  }}
                    </template>
                </Column>
                
                <Column field="quantidade" header="Qt. Faturado" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                    <template #body="{ data, field }">
                        {{ formatDecimal(data[field])  }}&nbsp;Kg
                    </template>
                </Column>
                
                <Column field="valorTotal" header="Vl. Total Faturado" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                    <template #body="{ data, field }">
                        R$&nbsp;{{ formatDecimal(data[field])  }}
                    </template>
                </Column>
                
                <Column field="quantidadeFixada" header="Qt. Fixada" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                    <template #body="{ data, field }">
                        {{ formatDecimal(data[field])  }}&nbsp;Kg
                    </template>
                </Column>

                <Column field="saldoFixacaoFaturamento" header="Saldo Fixação" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                    <template #body="{ data, field }">
                        {{ formatDecimal(data[field])  }}&nbsp;Kg
                    </template>
                </Column>
                
                <Column field="quantidadeFixacao" header="Qt. Fixação" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                    <template #body="{ data, field }">
                        {{ formatDecimal(data[field])  }}&nbsp;Kg
                    </template>
                </Column>

                <Column field="complementoFixacao" header="Compl. Fixação" class="alinhar-direita-titulo-tabela" style="width:10rem;">
                    <template #body="{ data }">
                        R$&nbsp;{{ formatDecimal(calcularComplementoFixacao(data))  }}
                    </template>
                </Column>

                <ColumnGroup type="footer">
                    <Row>
                        <Column footer="Total Fixação Nota Fiscal:" :colspan="6" footerStyle="text-align:right" />
                        <Column footerStyle="text-align:right">
                            <template #footer>
                                {{ formatDecimal(totalizador(slotProps.data.faturamentos, "quantidadeFixada")) }}&nbsp;Kg
                            </template>
                        </Column>

                        <Column footerStyle="text-align:right">
                            <template #footer>
                                {{ formatDecimal(totalizador(slotProps.data.faturamentos, "saldoFixacaoFaturamento")) }}&nbsp;Kg
                            </template>
                        </Column>
                        
                        <Column footerStyle="text-align:right">
                            <template #footer>
                                {{ formatDecimal(totalizador(slotProps.data.faturamentos, "quantidadeFixacao")) }}&nbsp;Kg
                            </template>
                        </Column>
                        
                        <Column footerStyle="text-align:right">
                            <template #footer>
                                R$&nbsp;{{ formatDecimal(totalizador(slotProps.data.faturamentos, "complementoFixacao")) }}
                            </template>
                        </Column>
                    </Row>
                </ColumnGroup>
            </DataTable>
        </template>
    </DataTable>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';

export default {
    name: "ModalFinalizacaoFixacaoNotasFiscais",
    props: {
        dados: { required: true },
        loading: { type: Boolean, required: true },
        cabecalho: { type: Object, required: true },
        siglaUnidadeMedida: { type: String, required: false },
        etapaFinal: { type: Boolean, required: true }
    },
    data() {
        return {
            expandedRows: []
        }
    },
    methods: {
        calcularComplementoFixacao(dados) {
            const valorPorQuilo = this.cabecalho.valorQuilo;
            const quantidadeFixacao = dados.quantidadeFixacao;
            const valorUnitarioFaturado = dados.valorUnitario;
            const valorFaturado = (quantidadeFixacao * valorUnitarioFaturado);
            const valorFixacao = (quantidadeFixacao * valorPorQuilo);

            dados.complementoFixacao = valorFixacao - valorFaturado;
            return dados.complementoFixacao;
        },

        bloquearSelecao(campo, pedido) {
            const faturamentos = pedido.faturamentos;
            const qtdFixacaoPedidoEmKg = Formatacao.converterEmQuilos(pedido.quantidadeFixacao, pedido.unidade);
            const qtdTotalFixacao = this.totalizador(faturamentos, "quantidadeFixacao");
            return !campo && qtdTotalFixacao >= qtdFixacaoPedidoEmKg;
        },

        selecionarNotaFiscal(dados, pedido) {
            if(!dados.selecionado) {
                const faturamentos = pedido.faturamentos;
                const qtdFixacaoPedidoEmKg = Formatacao.converterEmQuilos(pedido.quantidadeFixacao, pedido.unidade);
                const qtdTotalFixacao = this.totalizador(faturamentos, "quantidadeFixacao");
                const saldoParaFixacao = qtdFixacaoPedidoEmKg - qtdTotalFixacao;
                
                if(saldoParaFixacao <= dados.saldoFixacaoFaturamento) {
                    dados.quantidadeFixacao = saldoParaFixacao;
                }
                else {
                    dados.quantidadeFixacao = dados.saldoFixacaoFaturamento;
                }
            }
            else {
                dados.quantidadeFixacao = 0;
            }
        },

        totalizador(dados, campo) {
            if(dados == null || dados == undefined) return 0;
            const total = dados.reduce((valorAcumulado, item) => valorAcumulado + item[campo], 0);
            return isNaN(total) ? 0 : total;
        },

        convertDateToString(data, formato) {
            return Formatacao.convertDateToString(data, formato);
        },
        
        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format) {
            return Formatacao.formatDateCustom(data, format);
        },

        onRowExpand(event) {
            // this.toast.add({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
        },
        
        onRowCollapse(event) {
            // this.toast.add({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    
    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
