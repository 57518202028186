<template>
    <div class="card">
        <div class="flex align-items-center">
            <img src="layout/images/fixacao.png" style="width: 30px; font-size: 1.4rem">
           <h3 class="mt-3" style="margin:0px 5px;">Fixação de Preço</h3>
        </div>

        <hr />

        <div class="col-12 grid" style="padding-right: 0px !important;">

            <div class="col-6 xl:col-2">
                <label><strong>Nº CI</strong></label>
                <div class="flex align-items-center">
                    <div class="overflow-hidden">
                        <InputNumber v-model="filter.nrContratoInternoInicial"/>
                    </div>
                    <strong class="ml-1 mr-1">à</strong>
                    <div class="overflow-hidden">
                        <InputNumber v-model="filter.nrContratoInternoFinal" :min="filter.nrContratoInternoInicial" :max="999999" />
                    </div>
                </div>
            </div>

            <div class="col-6 xl:col-2">
                <label>
                    <strong>Cliente</strong>
                </label>

                <ProdutorFiltro
                    :disabled="false"
                    :selecionado="cliente"
                    :paginacao="cliente"
                    @pesquisar="pesquisarCliente"
                    @selecionado="selecionarCliente"
                />
            </div>

            <div class="col-6 xl:col-2">
                <label>
                    <strong>Status</strong>
                </label>

                <MultiSelect v-model="multiselectValue" :options="multiselectValues" optionLabel="name" placeholder="Todos" :filter="true" class="multiselect-custom w-full">
                    <template #value="slotProps">
                        <div class="status-fixacao-item status-fixacao-item-value" v-for="option of slotProps.value" :key="option.code">
                            <div>{{option.name}}</div>
                        </div>
                        <template v-if="!slotProps.value || slotProps.value.length === 0">
                            <div class="status-fixacao-placeholder">
                                Todos
                            </div>
                        </template>
                    </template>
                    <template #option="slotProps">
                        <div class="status-fixacao-item">
                            <div>{{slotProps.option.name}}</div>
                        </div>
                    </template>
                </MultiSelect>
            </div>

            <div class="col-6 xl:col-2">
                <label>
                    <strong>Data Fim</strong>
                </label>

                <div class="flex align-items-center">
                    <div class="overflow-hidden">
                        <Calendar
                            v-model="periodoInicial"
                            autocomplete="off"
                            dateFormat="dd/mm/y"
                        />
                    </div>

                    <strong class="ml-1 mr-1">à</strong>
                    
                    <div class="overflow-hidden">
                        <div class="p-inputgroup">
                            <Calendar
                                v-model="periodoFinal"
                                autocomplete="off"
                                dateFormat="dd/mm/y"
                                :minDate="periodoInicial"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 xl:col-4">
                <div class="p-inputgroup mt-3">
                    <Button
                        label="Filtrar"
                        icon="pi pi-filter"
                        class="p-button-outlined p-button-info"
                        @click="carregarDados()"
                    ></Button>

                    <Button 
                        title="Limpar Filtro"
                        icon="pi pi-filter-slash"
                        class="p-button-outlined p-button-info mr-2"
                        style="margin-left: 1px;"
                        @click="limparFiltro()"
                    ></Button>

                    <Button
                        v-if="filter.situacao == 'ATIVO'"
                        type="button"
                        icon="pi pi-ban"
                        label="Listar&nbsp;inativos"
                        class="p-button-outlined p-button-danger mr-2"
                        @click="filter.situacao = 'INATIVO'; carregarDados()"
                    ></Button>

                    <Button
                        v-if="filter.situacao == 'INATIVO'"
                        type="button"
                        icon="pi pi-check-circle"
                        label="Listar&nbsp;ativos"
                        class="p-button-outlined mr-2"
                        @click="filter.situacao = 'ATIVO'; carregarDados()"
                    ></Button>
                </div>

            </div>
        </div>


        <DataTable 
            responsiveLayout="stack"
            :value="data"
            v-model:selection="selecionado"
            selectionMode="single"
            showGridlines
            @sort="sort($event)">

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="contratoInternoId" header="Nº CI" style="width:4rem;">
                <template #body="{ data }">
                  <strong :title="data.modalidadePrecificacao">{{ data.contratoInternoId }}</strong>
                </template>
            </Column>

            <Column field="contratoCorretora" header="Nº Contrato Repres." style="width:9rem;"/>
            <Column field="cliente" header="Cliente">
                <template #body="{ data, field }">
                    {{ data[field].codigo }} - {{ data[field].nome }} ({{ data[field].cnpj }})
                </template>
            </Column>

            <Column field="qtdContrato" header="Qt. Contrato" style="width:10rem;text-align: right;">
                <template #body="{ data, field }">
                    {{ toKg(data[field],data.unidade) }} Kg
                </template>
            </Column>

            <Column field="qtdFixada" header="Qt. Fixada" style="width:10rem;text-align: right;">
                <template #body="{ data, field }">
                    {{ toKg(data[field],data.unidade) }} Kg
                </template>
            </Column>

            <Column field="qtdAFixar" header="Qt. À Fixar" style="width:10rem;text-align: right;">
                <template #body="{ data, field }">
                    {{ toKg(data[field],data.unidade) }} Kg
                </template>
            </Column>

            <Column field="qtdEmbarcada" header="Qt. Embarque" style="width:10rem;text-align: right;">
                <template #body="{ data, field }">
                    {{ toKg(data[field],data.unidade) }} Kg
                </template>
            </Column>

            <Column field="cliente" header="Representante">
                <template #body="{ data, field }">
                    {{ data[field].representante }}
                </template>
            </Column>

            <Column field="dataFimFixacao" header="Data Fim" style="width:6rem">
                <template #body="{ data, field }">
                    {{ formatDateCustom(data[field], "DD/MM/YY") }}
                </template>
            </Column>

            <Column field="statusFixacao" header="Status" style="width:6rem;">
                <template #body="{ data}">
                    {{ getDescricaoStatus(data.statusFixacao) }}
                </template>
            </Column>

            <Column header="Ações" style="width:10rem; text-align: center;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">

                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>

                        <Button
                            v-if="data.ativo"
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            @click="editar(data.id)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> o embarque do CI: <strong>{{ selecionado.contratoInternoId }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <ModalHistoricoGenerico
        codigoPagina="GESTAO_FIXACAO_PRECO"
        :id="selecionado.id"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />

</template>

<script>

import ProdutorFiltro from '../ProdutorFiltro.vue';
import Formatacao from '../../../utilities/Formatacao';
import StorageService from '../../../service/StorageService';
import ProdutorService from '../../../service/ProdutorService';
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import UnidadeMedidaService from '../../cadastro/contrato/service/UnidadeMedidaService.js';
import FixacaoPrecoContratoInternoService from '../../../service/fixacao/FixacaoPrecoContratoInternoService';

export default {
    name: 'FixacaoPrecoList',
    components: { ModalHistoricoGenerico, ProdutorFiltro },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,  

            data: [],

            filter: {},
            ordenar: 'id,desc',
            periodoInicial: null,
            periodoFinal: null,
            
            cliente: {},
            selecionado: {},
            historicoDialog: false,
            ativarOuInativarDialog: false,
            descricaoHistoricoDialog: null,

            multiselectValue: [],
            multiselectValues: [
                {name: 'À Fixar', code: 'AFIXAR'},
                {name: 'Fixado', code: 'FIXADO'}
            ],
            
            storange: StorageService.getControlePagina("GESTAO_FIXACAO_PRECO"),
        }
    },

    created() {
        this.filter.situacao = "ATIVO";

        if(this.storange.default == undefined){
            this.filter = this.storange.filtros;
        }

        this.pagina = this.storange.numero;
        this.primeiraLinha = this.storange.firstRow;
        this.qtdRegistroPorPagina = this.storange.qtdRegistro; 
        this.carregarDados();
    },

    methods: {
        editar(id) {                
            this.$router.push(`/fixacao-preco/editar/${ id }`);
        },

        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;
            this.filter["dataFimFixacaoInicial"] = undefined;
            this.filter["dataFimFixacaoFinal"] = undefined;
            this.filter["statusFixacao"] = undefined;

            if(!!this.periodoInicial) {
                this.filter["dataFimFixacaoInicial"] = this.formatDateCustom(this.periodoInicial, "YYYY-MM-DD");
            }

            if(!!this.periodoFinal) {
                this.filter["dataFimFixacaoFinal"] = this.formatDateCustom(this.periodoFinal, "YYYY-MM-DD");
            }

            if(this.multiselectValue.length > 0){
                const statusFixacao = this.multiselectValue.map(item => {
                    return item.code;
                });
                this.filter["statusFixacao"] = statusFixacao.join(",");
            }

            FixacaoPrecoContratoInternoService.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if(data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar os embarques',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });

            StorageService.setControlePaginaFilter("FIXACAO_PRECO", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },

        limparFiltro() {
            this.filter["clienteId"] = undefined
            this.filter["periodoInicial"] = undefined;
            this.filter["periodoFinal"] = undefined;
            this.filter["statusFixacao"] = undefined;
            this.filter["codigoCliente"] = undefined;
            this.filter["nrContratoInternoInicial"] = undefined;
            this.filter["nrContratoInternoFinal"] = undefined;

            this.multiselectValue = [];
            this.periodoInicial = null;
            this.periodoFinal = null;
            this.cliente = {};

            this.carregarDados();
        },

        confirmarAtivarOuInativar(embarque) {
            this.selecionado = embarque;
            this.ativarOuInativarDialog = true;
        },

        visualizarHistorico(embarque) {
            this.descricaoHistoricoDialog = "Contrato Interno: "+ embarque.contratoInternoId;
            this.historicoDialog = true;
            this.selecionado = embarque;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.selecionado = {};
        },

        ativarOuInativar() {
            if(this.selecionado.ativo) {
                FixacaoPrecoContratoInternoService.inativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`Gestão da fixação inativada com sucesso.`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao inativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                FixacaoPrecoContratoInternoService.ativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`Gestão da fixação ativada com sucesso.`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Falha ao ativar',
                            detail:`Falha não mapeada.`, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarDialog = false;
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        },

        getDescricaoStatus(status){
            
            if(status){
                switch(status){
                    case 'AFIXAR':
                        return "À Fixar";
                    case 'FIXADO':
                        return "Fixado"
                    default:
                        return null;
                };
            }
        },

        getSiglaUnidadeMedida(codigo){
            if(codigo){
                const unidadeMedida = UnidadeMedidaService.buscarPorCodigo(codigo);

                if(unidadeMedida){
                    return unidadeMedida.sigla;
                }else{
                    return "";
                }
            }
        },

        pesquisarCliente(filtros) {
            ProdutorService.pesquisarProdutor(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.cliente = data;
                    }
                })
                .catch(error => {
                    this.cliente = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os clientes.',
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarCliente(codCliente) {
            if(codCliente == undefined || codCliente == null || codCliente.trim() == "") {
                this.filter.codigoCliente = undefined;
                return;
            }

            ProdutorService.buscarPorCodProdutorInfoContrato(codCliente)
                .then(({ data }) => {
                    if(data) {
                        this.filter.codigoCliente = codCliente;
                        this.cliente = data;
                    }
                })
                .catch(error => {
                    this.cliente = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar o cliente.`,
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if(!!message) {
                            toast["detail"] = message.replace("produtor", "cliente");
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        toKg(vl,un){
          let fator = UnidadeMedidaService.buscarPorCodigo(un);
          return Formatacao.formatDecimal(vl * fator.fatorConversao,2,2);
        }

    }
}
</script>


<style scoped lang="scss">

    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

	::v-deep(.p-multiselect) {
		min-width: 8rem;
	}
	::v-deep(.multiselect-custom-virtual-scroll .p-multiselect) {
		min-width: 20rem;
	}
	::v-deep(.multiselect-custom .p-multiselect-label) {
		padding-top: .25rem;
		padding-bottom: .25rem;
	}
	::v-deep(.multiselect-custom .status-fixacao-item.status-fixacao-item-value) {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}
	::v-deep(.multiselect-custom .status-fixacao-item.status-fixacao-item-value img.flag) {
		width: 17px;
	}
	.status-fixacao-item {
		display: flex;
		align-items: center;
	}
	.status-fixacao-item img.flag {
		width: 18px;
		margin-right: .5rem;
	}
	::v-deep(.multiselect-custom .status-fixacao-placeholder) {
		padding: 0.25rem;
	}
	::v-deep(.p-chips .p-chips-token) {
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}
	::v-deep(.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token) {
		margin: 0.1rem 0.5rem 0.1rem 0;
	}
</style>