class UnidadeMedidaService {
    lista() {
        return [
            { descricao: "Tonelada", codigo: "TONELADA", sigla: "TON", fatorConversao: 1000 },
            { descricao: "Saca", codigo: "SACA", sigla: "SC", fatorConversao: 60 },
            { descricao: "Kilo", codigo: "KILO", sigla: "KG", fatorConversao: 1 },
        ]
    }

    buscarPorCodigo(codigo) {
        return this.lista().find(item => {
            return item.codigo === codigo;
        })
    }
}

export default new UnidadeMedidaService();