<template>
    <div class="card">
        <div class="w-full align-items-center">            
            <h3>
                <img src="layout/images/fixacao.png" style="width: 30px; font-size: 1.4rem">
                {{ id ? 'Editar' : 'Cadastrar'}} Fixação de Preço
            </h3>
        </div>

        <hr />

        <Fieldset legend="Contrato">
            <div class="grid p-fluid formgrid">

                <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-1">
                    <div class="field">
                        <label>
                            <strong>Nº CI</strong>
                        </label>
                        <InputText v-model="dados.contratoInternoId" class="w-full" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-5">
                    <div class="field">
                        <label>
                            <strong>Cliente</strong>
                        </label>
                        <InputText v-model="dados.clienteNome" class="w-full" :disabled="true" />
                    </div>
                </div>
                    
                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>CNPJ</strong>
                        </label>
                        <InputText v-model="dados.clienteCnpj" class="w-full" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Status</strong>
                        </label>
                        <InputText v-model="dados.statusDescricao" class="w-full" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Moeda</strong>
                        </label>
                        <InputText v-model="dados.moedaDescricao" class="w-full" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-5 md:col-5 lg:col-5 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Nº Contrato Repres.</strong>
                        </label>
                        <InputText v-model="dados.representanteNrContrato" class="w-full" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
                    <div class="field">
                        <label>
                            <strong>Representante</strong>
                        </label>
                        <InputText v-model="dados.representanteNome" class="w-full" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-3">
                    <div class="field">
                        <label>
                            <strong>Produto</strong>
                        </label>
                        <InputText v-model="dados.produtoDescricao" class="w-full" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Qt. Dev. Classif.</strong>
                        </label>
                        <div class="p-inputgroup">
                            <InputText v-model="dados.qtdDevClassif" class="w-full" :disabled="true" />
                            <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="dados.siglaUnidadeMedida"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Dt. Fim Fixação</strong>
                        </label>
                        <InputText v-model="dados.dataFimFixacao" class="w-full" :disabled="true" />
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Qt. Contrato</strong>
                        </label>
                        <div class="p-inputgroup">
                            <InputText v-model="dados.qtdContrato" class="w-full" :disabled="true" />
                            <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="dados.siglaUnidadeMedida"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Fix. Pendente Finalização</strong>
                        </label>
                        <div class="p-inputgroup">
                            <InputText v-model="dados.qtdFixPendenteFinalizacao" class="w-full" :disabled="true" />
                            <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="dados.siglaUnidadeMedida"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Qt. Fixada</strong>
                        </label>
                        <div class="p-inputgroup">
                            <InputText v-model="dados.qtdFixada" class="w-full" :disabled="true" />
                            <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="dados.siglaUnidadeMedida"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Qt. à Fixar</strong>
                        </label>
                        <div class="p-inputgroup">
                            <InputText v-model="dados.qtdAFixar" class="w-full" :disabled="true" />
                            <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="dados.siglaUnidadeMedida"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Qt. Embarcada</strong>
                        </label>
                        <div class="p-inputgroup">
                            <InputText v-model="dados.qtdEmbarcada" class="w-full" :disabled="true" />
                            <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="dados.siglaUnidadeMedida"/>
                        </div>
                    </div>
                </div>

                <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
                    <div class="field">
                        <label>
                            <strong>Situação Embarque</strong>
                        </label>
                        <InputText v-model="dados.embarqueSituacaoDescricao" class="w-full" :disabled="true" />
                    </div>
                </div>
            </div>
        </Fieldset>

        <TabView class="mt-3">
            <TabPanel>
                <template #header>
                    <i class="pi pi-list mr-2" style="font-size: 1rem;"></i>
                    <span>Fixações</span>
                </template>

                <FixacaoPrecoFixacoes 
                    :fixacaoPrecoContratoInternoId="dados.id" 
                    :contratoInternoId="dados.contratoInternoId" 
                    :quantidadeContrato="dados.quantidadeContrato" 
                    :siglaUnidadeMedida="dados.siglaUnidadeMedida"
                    :contratoCompraVendaId="dados.contratoCompraVendaId"
                    :ccv="dados.ccv"
                />
            </TabPanel>

            <TabPanel>
                <template #header>
                    <i class="pi pi-file mr-2" style="font-size: 1rem;"></i>
                    <span>Gestão de Fixação</span>
                </template>

                <FixacaoPrecoGestaoFixacao 
                    :dados="dados"
                    :fixacaoPrecoContratoInternoId="dados.id" 
                    :siglaUnidadeMedida="dados.siglaUnidadeMedida"
                    :contratoCompraVendaId="dados.contratoCompraVendaId"
                />
            </TabPanel>
        </TabView>

        <div class="flex mt-3">
            <Button
                icon="pi pi-times"
                label="Cancelar"
                class="p-button-danger ml-2"
                @click="voltar"
            ></Button>
        </div>
    </div>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import FixacaoPrecoFixacoes from './FixacaoPrecoFixacoes.vue';
import FixacaoPrecoService from '../../../service/FixacaoPrecoService';
import FixacaoPrecoGestaoFixacao from './FixacaoPrecoGestaoFixacao.vue';
import UnidadeMedidaService from '../../cadastro/contrato/service/UnidadeMedidaService.js';
import ContratoCompraVendaService from "@/service/ContratoCompraVendaService";

export default {
    name: "FixacaoPrecoForm",
    components: { FixacaoPrecoFixacoes, FixacaoPrecoGestaoFixacao },
    props: {
        id: { required: false }
    },
    data() {
        return {
            dados: {},

            campoInvalido: {},
            contratoConsulta: {}
        }
    },
    methods: {

        preencherFormulario() {
            FixacaoPrecoService.buscarPorId(this.id)
                .then(({ data }) => { 
                    const dados = data;
                    dados["statusDescricao"] = data.status == null ? null : data.status.descricao;
                    dados["tipoFixacaoCodigo"] = data.tipoFixacao == null ? null : data.tipoFixacao.codigo;
                    dados["embarqueSituacaoDescricao"] = data.embarqueSituacao == null ? null : dados.embarqueSituacao.descricao;
                    dados["moedaDescricao"] = data.moeda == null ? null : data.moeda.descricao;
                    dados["dataFimFixacao"] = Formatacao.convertDateToString(data.dataFimFixacao, "DD/MM/YYYY");
                    dados["qtdDevClassif"] = Formatacao.formatDecimal(data.qtdDevClassif, 2, 2);
                    dados["quantidadeContrato"] = data.qtdContrato;
                    dados["qtdContrato"] = Formatacao.formatDecimal(data.qtdContrato, 2, 2);
                    dados["qtdFixPendenteFinalizacao"] = Formatacao.formatDecimal(data.qtdFixPendenteFinalizacao, 2, 2);
                    dados["qtdFixada"] = Formatacao.formatDecimal(data.qtdFixada, 2, 2);
                    dados["qtdAFixar"] = Formatacao.formatDecimal(data.qtdAFixar, 2, 2);
                    dados["qtdEmbarcada"] = Formatacao.formatDecimal(data.qtdEmbarcada, 2, 2);
                    dados["siglaUnidadeMedida"] = this.getSiglaUnidadeMedida(data.unidade);

                    this.dados = dados;

                });

        },

        getSiglaUnidadeMedida(codigo){
            if(!codigo) return "";
            const unidadeMedida = UnidadeMedidaService.buscarPorCodigo(codigo);
            if(unidadeMedida) { return unidadeMedida.sigla; }
            return "";
        },

        voltar() {
            this.$router.push(`/fixacao-preco`);
        }
    },

    mounted(){
        if(!!this.id) this.preencherFormulario();
    }
}
</script>

<style scoped>

    .valor-campo-numerico{
        text-align: right; 
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
        width: 70px !important; 
        font-weight: 600;
    }
</style>
