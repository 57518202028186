import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class FixacaoPrecoService {
    buscarPorId(fixacaoPrecoId) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco-contrato-interno/${ fixacaoPrecoId }`);
    }

    buscarFixacaoPorId(id) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco/${ id }`);
    }

    buscarComPaginacao(params) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco/buscar-com-paginacao`, { params });
    }

    buscarTotalGeral(params) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco/total-geral-fixacao`, { params });
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco/inativar/${ id }`);
    }

    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/fixacao-preco/ativar/${ id }`);
    }
}

export default new FixacaoPrecoService();