<template>
    <Dialog v-model:visible="visivel" :style="{width: '500px', margin: '10%'}" :modal="true" @hide="fechar()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <h4 style="margin:0;"><span v-if="isEdicao">Editar&nbsp;</span>Fixar Cotação do Indicador</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid p-fluid formgrid">
            <div class="col-6">
                <div class="field">
                    <label for="indicador">
                        <strong>Indicador&nbsp;<span style="color: red;">*</span></strong>
                    </label>
                    <AutoComplete
                        placeholder="Selecione..." 
                        dropdown
                        v-model="dados.indicador"
                        field="sigla"
                        id="indicador"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.indicador }"
                        :suggestions="indicadoresFiltro"
                        :forceSelection="true"
                        :disabled="isEdicao"
                        @complete="buscarIndicadores"
                        @item-select="selecionarIndicador"
                    />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="quantidade_disponivel">
                        <strong>Quantidade Disponível ({{siglaUnidadeMedida}})</strong>
                    </label>
                    <InputNumber 
                        v-model="qtdDisponivel"
                        locale="pt-BR"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        disabled
                    />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="preco-unitario">
                        <strong>Valor (R$)&nbsp;<span style="color: red;">*</span></strong>
                    </label>
                    <InputNumber 
                        v-model="dados.precoUnitario"
                        id="preco-unitario"
                        locale="pt-BR"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        :class="{ 'p-invalid': campoInvalido.precoUnitario }"
                    />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="quantidade">
                        <strong>Quantidade ({{siglaUnidadeMedida}})&nbsp;<span style="color: red;">*</span></strong>
                    </label>
                    <InputNumber 
                        v-model="dados.quantidade"
                        id="quantidade"
                        locale="pt-BR"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        :class="{ 'p-invalid': campoInvalido.quantidade }"
                        @update:modelValue="calcularQtdDisponivel"
                    />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="data-fixacao">
                        <strong>Data Fixação&nbsp;<span style="color: red;">*</span></strong>
                    </label>
                    <Calendar
                        v-model="dados.dataFixacao"
                        id="data-fixacao"
                        dateFormat="dd/mm/yy"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.dataFixacao }"
                    />
                </div>
            </div>

            <div class="col-6">
                <div class="field">
                    <label for="mes-ano-base">
                        <strong>Mês/Ano Base&nbsp;<span style="color: red;">*</span></strong>
                    </label>
                    <Calendar 
                        v-model="dados.dataBase"
                        view="month"
                        dateFormat="MM/yy"
                        :class="{ 'p-invalid': campoInvalido.dataBase }"
                    />
                </div>
            </div>

            <div class="col-12">
                <div class="field">
                    <label for="observacao">
                        <strong>Observação</strong>
                    </label>
                    <Textarea v-model="dados.observacao" id="observacao"></Textarea>
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-center">
                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info ml-1"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import FixacaoPrecoIndicadorService from '../../../service/FixacaoPrecoIndicadorService';
import TratamentoDeError from '../../../utilities/TratamentoDeError';

export default {
    name: "ModalFixarCotacaoIndicador",
    emits: ["fechar"],
    props: {
        visivel: { required: true },
        fixacaoPrecoContratoInternoId: { required: true },
        siglaUnidadeMedida: { required: true },
        quantidadeContrato: { required: true },
        qtdLancadaPorIndicador: { required: true },
        fixacaoPreco: { required: false },
        indicadores: { required: false }
    },
    watch: {
        fixacaoPreco(obj) {
            const objIsNotNull = obj != null;
            const objIsNotUndefined = obj != undefined;
            const objIsKeys = Object.keys(obj).length > 0;
            this.isEdicao = objIsNotNull && objIsNotUndefined && objIsKeys;
            if(this.isEdicao) this.preencherFormulario(obj);
        }
    },
    data() {
        return {
            isEdicao: false,
            mensagem: { ativo: false, gravidade: 'info', conteudo: '' },

            dados: {},
            campoInvalido: {},
            qtdDisponivel: 0,

            indicadoresFiltro: []
        }
    },
    methods: {
        limparMensagem() {
            this.mensagem = { ativo: false };
        },

        getJson() {
            const objJson = {};
            objJson["id"] = this.dados.id;
            objJson["fixacaoPrecoContratoInternoId"] = this.fixacaoPrecoContratoInternoId;
            objJson["itemComposicaoValorId"] = this.dados.itemComposicaoValorId == null ? this.dados.indicador.itemComposicaoValorId : this.dados.itemComposicaoValorId;
            objJson["precoUnitario"] = this.dados.precoUnitario;
            objJson["quantidade"] = this.dados.quantidade;
            objJson["dataFixacao"] = Formatacao.convertDateToString(this.dados.dataFixacao, 'YYYY-MM-DD HH:mm:ss');
            objJson["dataBase"] = Formatacao.convertDateToString(this.dados.dataBase, 'YYYY-MM-DD');
            objJson["observacao"] = this.dados.observacao;
            objJson["versao"] = this.dados.versao;
            objJson["indicador"] = { id: this.dados.indicador.id };
            return objJson;
        },

        isCamposObrigatoriosPreenchidos() {
            const campos = [];

            if(!(this.dados.indicador && this.dados.indicador.id)) {
                campos.push("Indicador");
                this.campoInvalido.indicador = true;
            }
            else {
                this.campoInvalido.indicador = false;
            }

            if(!this.dados.precoUnitario) {
                campos.push("Preço Unitário");
                this.campoInvalido.precoUnitario = true;
            }
            else {
                this.campoInvalido.precoUnitario = false;
            }
            
            if(!this.dados.quantidade) {
                campos.push("Quantidade");
                this.campoInvalido.quantidade = true;
            }
            else {
                this.campoInvalido.quantidade = false;
            }
            
            if(!this.dados.dataFixacao) {
                campos.push("Data Fixação");
                this.campoInvalido.dataFixacao = true;
            }
            else {
                this.campoInvalido.dataFixacao = false;
            }

            if(!this.dados.dataBase) {
                campos.push("Data Base");
                this.campoInvalido.dataBase = true;
            }
            else {
                this.campoInvalido.dataBase = false;
            }
            
            if(this.qtdDisponivel < 0){
                this.mensagem = {
                    ativo: true,
                    gravidade: 'warn',
                    conteudo: 'A quantidade fixada não pode ser maior que a quantidade disponível.'
                };
                
                return false;
            }

            if(campos.length > 0) {
                let mensagem = `Por favor, preencher o campo ${ campos[0] }`;

                if(campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${ mensagem.substring(0, indice) } e ${ mensagem.substring(indice + 1, mensagem.length) }.`;
                }

                this.limparMensagem();
                this.mensagem = {
                    ativo: true,
                    gravidade: 'warn',
                    conteudo: mensagem
                };
                
                return false;
            }
            return true;
        },

        salvar() {
            if(!this.isCamposObrigatoriosPreenchidos()) return;
            FixacaoPrecoIndicadorService.salvar(this.getJson())
                .then(({ data }) => {
                    if(!!this.dados.id) {
                        this.fechar();
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Fixação de Preço do Indicador salvo',
                            detail: `A fixação de preço do indicador codigo ${ data.id } foi salvo com sucesso.`, 
                            life: 14000
                        });
                    }
                    else {
                        this.limparMensagem();
                        this.dados = {}
                        this.mensagem = {
                            ativo: true,
                            gravidade: 'success',
                            conteudo: 'Foi adicionado com sucesso!'
                        };
                    }
                })
                .catch(error => {
                    const tratamentoDeErro = TratamentoDeError(error);
                    const mensagem = tratamentoDeErro.detail;
                    this.limparMensagem();
                    this.mensagem = { ativo: true, gravidade: 'error', conteudo:  mensagem };
                });
        },

        fechar() {
            this.limparMensagem();
            this.dados = {}
            this.qtdDisponivel = 0;
            this.$emit("fechar");
        },

        buscarIndicadores(event) {
            if (!event.query.trim().length) {
                this.indicadoresFiltro = [...this.indicadores];
            } else {
                this.indicadoresFiltro = this.indicadores.filter((item) => {
                    return item.sigla.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        calcularQtdDisponivel() {
            if(!this.dados.indicador || !this.quantidadeContrato) return;
            const qtdLancada = this.qtdLancadaPorIndicador[this.dados.indicador.sigla];
            let qtdDisponivel = this.quantidadeContrato - qtdLancada;

            if(this.isEdicao) qtdDisponivel += this.dados.quantidadeOriginal;
            this.qtdDisponivel = qtdDisponivel - (!!this.dados.quantidade ? this.dados.quantidade : 0);
        },

        selecionarIndicador() {
            this.calcularQtdDisponivel();
        },

        preencherFormulario(obj) {
            const dados = {};
            dados["id"] = obj.id;
            dados["fixacaoPrecoContratoInternoId"] = obj.fixacaoPrecoContratoInternoId;
            dados["itemComposicaoValorId"] = obj.itemComposicaoValorId;
            dados["precoUnitario"] = obj.precoUnitario;
            dados["quantidadeOriginal"] = obj.quantidade;
            dados["quantidade"] = obj.quantidade;
            dados["dataFixacao"] = Formatacao.convertStringToDate(obj.dataFixacao, 'YYYY-MM-DD HH:mm:ss');
            dados["dataBase"] = Formatacao.convertStringToDate(obj.dataBase, 'YYYY-MM-DD');
            dados["observacao"] = obj.observacao;
            dados["versao"] = obj.versao;
            dados["indicador"] = obj.indicador;
            this.dados = dados;
            this.calcularQtdDisponivel();
        }
    }
}
</script>