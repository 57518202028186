<template>
    <DataTable :value="dados">
        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-chart-line mr-2" style="font-size: 1.5rem;"></i>
                <strong style="font-size: 13pt; color: #2f3030;">Indicadores selecionados</strong>
            </div>
        </template>

        <Column field="indicadorSigla" header="Indicador" style="width:8rem;">
            <template #body="{ data, field }">
                {{ data[field] }}
            </template>
        </Column>
        
        <Column field="precoUnitario" header="Valor (Kg)" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                R$ {{ formatDecimal(data[field], 4, 4) }}
            </template>
        </Column>
        
        <Column field="dataFixacaoPrecoIndicador" header="Data" style="width:10rem;">
            <template #body="{ data, field }">
                {{ convertDateToString(data[field], 'DD/MM/YYYY') }}
            </template>
        </Column>
        
        <Column field="dataBase" header="Mês/Ano Base" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDateCustom(data[field], "MMMM/YYYY")  }}
            </template>
        </Column>

        <Column field="observacao" header="Observação" />
        
        <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela"  v-if="!etapaFinal">
            <template #body="{ data, index }">
                <div class="flex align-items-center justify-content-center">
                    <Button 
                        title="Visualizar histórico"
                        icon="pi pi-list"
                        class="p-button-rounded p-button-warning flex-none mr-2"
                        :disabled="true"
                    ></Button>

                    <Button
                        class="p-button-rounded p-button-info tamanho-botao-tabela flex-none mr-2"
                        icon="pi pi-pencil"
                        aria-label="Editar"
                        :disabled="true"
                    ></Button>
                    
                    <Button 
                        v-if="data.ativo"
                        title="Inativar"
                        class="p-button-rounded flex-none p-button-danger"
                        icon="pi pi-ban"
                        :disabled="true"
                    ></Button>

                    <Button 
                        v-if="!data.ativo"
                        title="Ativar"
                        class="p-button-rounded flex-none p-button-info"
                        icon="pi pi-check-circle"
                        :disabled="true"
                    ></Button>
                </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';

export default {
    name: "ModalFinalizacaoFixacaoIndicadoresSelecionados",
    props: {
        dados: { required: true },
        etapaFinal: { type: Boolean, required: true}
    },
    data() {
        return { }
    },
    methods: {
        convertDateToString(data, formato) {
            return Formatacao.convertDateToString(data, formato);
        },
        
        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format) {
            return Formatacao.formatDateCustom(data, format);
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    
    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
