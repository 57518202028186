<template>
    <DataTable :value="dados.pedidos" :loading="loading">
        <template #header>
            <div class="flex align-items-center">
                <i class="pi pi-book mr-2" style="font-size: 1.5rem;"></i>
                <strong style="font-size: 13pt; color: #2f3030;">Pedidos do Contrato</strong>
            </div>
        </template>

        <template #empty>
            Nenhum registro encontrado.
        </template>

        <template #loading>
            Carregando... Por favor, aguarde.
        </template>

        <Column field="codigoEstabelecimento" header="Estab.">
            <template #body="{ data, field }">
                {{ data[field] }}
            </template>
        </Column>
        
        <Column field="pedidoId" header="Nº Pedido">
            <template #body="{ data, field }">
                {{ data[field] }}
            </template>
        </Column>
        
        <Column field="quantidadePedido" header="Quantidade" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>
        
        <Column field="quantidadeEmbarque" header="Qt. Embarque" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>
        
        <Column field="saldoPedido" header="Saldo Pedido" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>
        
        <Column field="valorTotalFaturado" header="Vl. Total Faturado" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                R$&nbsp;{{ formatDecimal(data[field])  }}
            </template>
        </Column>
        
        <Column field="quantidadeFixada" header="Qt. Fixada" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>
        
        <Column field="saldoFixacaoPedido" header="Saldo Fixação" class="alinhar-direita-titulo-tabela" style="width:10rem;">
            <template #body="{ data, field }">
                {{ formatDecimal(data[field])  }}&nbsp;{{ siglaUnidadeMedida }}
            </template>
        </Column>
        
        <Column field="quantidadeFixacao" header="Qt. Fixação" class="alinhar-direita-titulo-tabela" style="width:15rem;">
            <template #body="{ data, field }">
                <div class="flex align-items-center">
                    <div class="p-inputgroup">
                        <InputNumber 
                            v-model="data[field]"
                            id="quantidade"
                            locale="pt-BR"
                            :max="data['saldoFixacaoPedido']"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            :disabled="!data['selecionado'] || etapaFinal"
                            class="w-full"
                        />
                        <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                    </div>
                </div>
            </template>
        </Column>

        <Column field="selecionado" class="centralizar-titulo-tabela" style="width:3rem;">
            <template #body="{ data, field }">
                <div class="flex align-items-center">
                    <Checkbox :disabled="etapaFinal" v-model="data[field]" id="sim" binary @click="selecionarPedido(data)" />
                </div>
            </template>
        </Column>

        <ColumnGroup type="footer">
            <Row>
                <Column footer="Total:" :colspan="2" footerStyle="text-align:right" />
                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(totalizador("quantidadePedido")) }}&nbsp;{{ siglaUnidadeMedida }}
                    </template>
                </Column>
                
                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(totalizador("quantidadeEmbarque")) }}&nbsp;{{ siglaUnidadeMedida }}
                    </template>
                </Column>
                
                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(totalizador("saldoPedido")) }}&nbsp;{{ siglaUnidadeMedida }}
                    </template>
                </Column>

                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(totalizador("valorTotalFaturado")) }}&nbsp;{{ siglaUnidadeMedida }}
                    </template>
                </Column>

                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(totalizador("quantidadeFixada")) }}&nbsp;{{ siglaUnidadeMedida }}
                    </template>
                </Column>

                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(totalizador("saldoFixacaoPedido")) }}&nbsp;{{ siglaUnidadeMedida }}
                    </template>
                </Column>

                <Column footerStyle="text-align:right">
                    <template #footer>
                        {{ formatDecimal(totalizador("quantidadeFixacao")) }}&nbsp;{{ siglaUnidadeMedida }}
                    </template>
                </Column>

                <Column />
            </Row>
        </ColumnGroup>
    </DataTable>
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';

export default {
    name: "ModalFinalizacaoFixacaoPedidosContrato",
    props: {
        dados: { type: Object, required: false },
        loading: { type: Boolean, required: true },
        siglaUnidadeMedida: { type: String, required: false },
        etapaFinal: { type: Boolean, required: true}
    },
    data() {
        return { }
    },
    methods: {
        selecionarPedido(data) {
            data["quantidadeFixacao"] = 0;
        },

        totalizador(campo) {
            if(this.dados == null || this.dados == undefined || this.dados.pedidos == null || this.dados.pedidos == undefined) return 0;
            const total = this.dados.pedidos.reduce((valorAcumulado, item) => valorAcumulado + item[campo], 0);
            return isNaN(total) ? 0 : total;
        },

        convertDateToString(data, formato) {
            return Formatacao.convertDateToString(data, formato);
        },
        
        formatDecimal(value, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return Formatacao.formatDecimal(value, minimumFractionDigits, maximumFractionDigits);
        },

        formatDateCustom(data, format) {
            return Formatacao.formatDateCustom(data, format);
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }

    
    .alinhar-direita-titulo-tabela {
        text-align: right !important;
    }

    .alinhar-direita-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
