<template>
    <FixacaoPrecoList v-if="!isCadastrar()" />
    <FixacaoPrecoForm v-if="isCadastrar()" :id="id" />
</template>

<script>
import FixacaoPrecoList from '../../components/cadastro/fixacao/FixacaoPrecoList.vue';
import FixacaoPrecoForm from '../../components/cadastro/fixacao/FixacaoPrecoForm.vue';

export default {
    name: 'FixacaoPrecoPage',
    components: { FixacaoPrecoList, FixacaoPrecoForm },

    data() {
        return {
            isCadastro: false,
            isEditar: false,
            id: null
        }
    },

    created() {
        this.validarRouter();
    },

    updated() {
        this.validarRouter();
    },

    methods: {
        editar(id) {               
            if(id) {
                this.id = id;
                this.isEditar = true;
            }
            else {
                this.id = null;
                this.isEditar = false;
            }
        },

        isCadastrar() {
            return this.isCadastro || this.isEditar;
        },

        validarRouter() {
            const routerName = this.$route.name
            if(routerName === 'fixacao-preco-cadastro') {
                this.isCadastro = true;
            }
            else if(routerName === 'fixacao-preco-editar') {
                const id = parseInt(this.$route.params.id);
                this.editar(id);
            }
            else {
                this.isCadastro = false;
                this.isEditar = false;
                this.id = null;
            }
        }
    }
}
</script>