<template>
    <div class="grid p-fluid formgrid">
        <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-1">
            <div class="field">
                <label for="nr-ci">
                    <strong>Nº CI</strong>
                </label>
                <InputText v-model="dados.nrCi" id="nr-ci" class="w-full" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-6 md:col-6 lg:col-6 xl:col-5">
            <div class="field">
                <label for="cliente">
                    <strong>Cliente</strong>
                </label>
                <InputText v-model="dados.clienteNome" id="cliente" class="w-full" :disabled="true" />
            </div>
        </div>
            
        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
            <div class="field">
                <label for="cnpj">
                    <strong>CNPJ</strong>
                </label>
                <InputText v-model="dados.clienteCnpj" id="cnpj" class="w-full" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-3 md:col-3 lg:col-3 xl:col-2">
            <div class="field">
                <label for="regra-fixacao">
                    <strong>Regra de Fixação</strong>
                </label>
                <InputText v-model="dados.descricaoRegra" id="regra-fixacao" class="w-full" :disabled="true" />
            </div>
        </div>

        <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-1">
            <div class="field">
                <label for="data-fixacao">
                    <strong>Data Fixação</strong>
                </label>
                <Calendar
                    v-model="dados.dataFixacao"
                    class="w-full"
                    id="data-fixacao"
                    dateFormat="dd/mm/yy"
                    :disabled="true"
                />
            </div>
        </div>

        <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-1">
          <div class="field">
            <label for="quilo">
              <strong>Quilo</strong>
            </label>
            <div class="p-inputgroup">
              <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
              <InputNumber
                  v-model="dados.valorQuilo"
                  id="quilo"
                  class="w-full"
                  locale="pt-BR"
                  :minFractionDigits="4"
                  :maxFractionDigits="4"
                  disabled/>
            </div>
          </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
            <div class="field">
                <label for="qt-fixacao">
                    <strong>Qt. Fixação</strong>
                </label>
                <div class="flex align-items-center">
                    <div class="p-inputgroup">
                        <InputNumber 
                            v-model="dados.quantidade"
                            id="qt-fixacao"
                            locale="pt-BR"
                            class="w-full"
                            :max="9999999"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            :disabled="true"
                        />
                        <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
            <div class="field">
                <label for="qt-a-fixar">
                    <strong>Qt. à Fixar</strong>
                </label>
                <div class="flex align-items-center">
                    <div class="p-inputgroup">
                        <InputNumber 
                            v-model="dados.qtdAFixar"
                            id="qt-a-fixar"
                            locale="pt-BR"
                            class="w-full"
                            :max="9999999"
                            :minFractionDigits="2"
                            :maxFractionDigits="2"
                            :disabled="true"
                        />
                        <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="siglaUnidadeMedida"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
            <div class="field">
                <label for="valor-saca-60">
                    <strong>Valor Saca 60Kg</strong>
                </label>
                <div class="p-inputgroup">
                    <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
                    <InputNumber 
                        v-model="dados.valorSaca"
                        id="valor-saca-60"
                        class="w-full valor-campo-numerico"
                        locale="pt-BR"
                        :minFractionDigits="4"
                        :maxFractionDigits="4"
                        disabled/>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
            <div class="field">
                <label for="valor-tonelada">
                    <strong>Valor Tonelada</strong>
                </label>
                <div class="p-inputgroup">
                    <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
                    <InputNumber 
                        v-model="dados.valorTonelada"
                        id="valor-tonelada"
                        class="w-full valor-campo-numerico"
                        locale="pt-BR"
                        :minFractionDigits="4"
                        :maxFractionDigits="4"
                        disabled/>
                </div>
            </div>
        </div>

        <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
            <div class="field">
                <label for="valor-total-fixacao">
                    <strong>Valor Total da Fixação</strong>
                </label>
                <div class="p-inputgroup">
                    <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
                    <InputNumber 
                        v-model="dados.valorTotal"
                        id="valor-total-fixacao"
                        class="w-full valor-campo-numerico"
                        locale="pt-BR"
                        :minFractionDigits="2"
                        :maxFractionDigits="2"
                        disabled/>
                </div>
            </div>
        </div>

      <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-2">
        <div class="field">
          <label for="valor-total-fixacao" v-if="valorNotaComplementar>0">
            <strong style="color: #FF0000">Valor Nota Complementar</strong>
          </label>
          <label for="valor-total-fixacao" v-if="valorNotaComplementar<1">
            <strong>Valor Nota Complementar</strong>
          </label>

          <div class="p-inputgroup">
            <InputText :disabled="true" class="p-inputgroup-addon sigla-campo-numerico" v-model="simboloMoeda"/>
            <InputNumber
                v-model="valorNotaComplementar"
                id="valor-total-fixacao"
                class="w-full valor-campo-numerico"
                locale="pt-BR"
                :minFractionDigits="2"
                :maxFractionDigits="2"
                disabled/>
          </div>
        </div>
      </div>

    </div>
  <div class="flex align-items-center" v-if="etapaFinal">
    <strong style="font-size: 12pt; color: #2f3030;">Resumo da Fixação</strong>
  </div><p></p>

</template>

<script>
export default {
    name: "ModalFinalizacaoFixacaoCabecalho",
    props: {
        dados: { type: Object, required: true },
        valorNotaComplementar: { type: Object, required: true },
        siglaUnidadeMedida: { required: true },
        etapaFinal: { type: Boolean, required: true }
    },
    data() {
        return { 
            simboloMoeda: "R$"
        }
    },
    methods: { }
}
</script>

<style>

    .valor-campo-numerico{
        text-align: right;
        font-weight: bold;
    }
    .sigla-campo-numerico{
        text-align: left; 
        width: 70px !important;
        font-weight: 600;
    }

</style>